import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { InspectionPlan } from 'src/app/models/inspection-plan';
import { ApiService } from 'src/app/services/api.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-top-label',
  templateUrl: './top-label.component.html',
  styleUrls: ['./top-label.component.scss']
})
export class TopLabelComponent implements OnInit {

  @Input() label: string;
  @Input() type: string;

  constructor(
    private stateService: StateService,
    private apiService: ApiService
  ) { }

  public handleAdd(): void {
    if(this.stateService.getDetailItem()) {
      switch(this.stateService.getDetailItem() && this.stateService.getDetailItem().getType()) {
        case 'facility':
          this.stateService.setActiveForm('equipment');
          break;
        case 'inspection-plan':
          this.stateService.setActiveForm('report');
      }
    } else {
      switch(this.stateService.getActivePanel()) {
        case 'inspection-plans':
          this.stateService.setActiveForm('inspection-plan');
          break;
        case 'facilities':
          this.stateService.setActiveForm('facility');
      }
    }
  }

  public backToMenu(): void {
    this.stateService.clearActiveForm();
    this.stateService.clearActivePanel();
    this.stateService.clearDetailItem();
  }

  public hasAdd(): boolean {
    if(this.stateService.getDetailItem()) {
      switch(this.stateService.getDetailItem().getType()) {
        case 'facility':
        case 'inspection-plan':
          return true;
      }
    } else {
      switch(this.stateService.getActivePanel()) {
        case 'inspection-plans':
        case 'facilities':
          return true;
      }
    }
    return false;
  }

  public hasRefresh(): boolean {
    if(this.stateService.getDetailItem() && this.stateService.getDetailItem().getType() == 'inspection-plan') {
      return true;
    }
    return false;
  }

  public refresh(): void {
    if(this.stateService.getDetailItem() && this.stateService.getDetailItem().getType() == 'inspection-plan') {
      this.apiService.refreshInspectionPlan(<InspectionPlan>this.stateService.getDetailItem());
    }
  }

  ngOnInit(): void {
  }

}
