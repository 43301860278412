import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Equipment } from '../../models/equipment';
import { InspectionPlan } from '../../models/inspection-plan';
import { StateService } from '../../services/state.service';
import { EquipmentSelection, Report, ReportParameters, ReportType } from './report';
import { ReportComponent } from './report/report.component';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit {

  @Input() plan: InspectionPlan;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('reportComponent') reportComponent: ReportComponent;

  private types: ReportType[];
  private logoTypes: ReportType[];

  public report: Report;

  private parameters: ReportParameters;

  constructor(
    public stateService: StateService
  ) {

    this.types = [
      {
        name: 'delta-thermal',
        description: 'Equipment status report - Delta thermal'
      }
    ];
    this.logoTypes = [
      {
        name: 'org-icon',
        description: 'Use organization icon (Default)'
      }
    ];
    this.parameters = {
      execution: null,
      type: this.types[0],
      title: '',
      logo: this.logoTypes[0],
      equipment: [],
      introTitle: 'Virkt hitaeftirlit',
      intro: 'Eitt af aðaleinkennum bilana í rafbúnaði er aukin hitaútgeislun. Með virku hitaeftirliti er mögulegt að fá greinargóða mynd af ástandi rafbúnaðar og fyljast með hvernig ástand hans þróast yfir tíma. Niðurstöður eftirlitsins eru því lykilþáttur í fyrirbyggjandi viðhaldi og mati á framtíðarviðhaldsþörf. Mikilbægt er að eftirlit sé að jafnaði framkvæmt 1 - 2 sinnum á ári. Með góðri yfirsýn yfir ástand rafbúnaðar/rafkerfis er mögulegt að lágmarka áhættu á rekstrartruflunum og eldsvoðum vegna bilana í rafbúnaði.',
      methodology: 'Æskilegt er að tímasetja eftirlit á þeim tíma dags þegar mest álag er á rafkerfinu til að fá sem raunverulegasta mynd af ástandi kerfisins. Virkt hitaeftirlit er framkvæmt með notkun innrauðrar hitamyndavélar á snertilausan máta og hefur því engin truflandi áhrif á rafkerfið eða rekstur. Eftirlitið og mælingarnar eru framkvæmdar af sérfræðing í hitaeftirliti, en rafvirki sér um að opna allar töflur. Við lok framkvæmdar eru mælingarnar greindar og niðurstöður settar fram í skýrslu.',
      conclusion: ''
    };
    this.report = new Report(this.parameters);
  }

  ngOnInit(): void {
    for(let i: number = 0; i < this.plan.equipment.length; i++) {
      const equipment: Equipment = this.plan.equipment[i];
      this.parameters.equipment.push({
        equipment: equipment,
        selected: false
      });
    }
  }

  public hasReport(): boolean {
    if(this.report && this.report.execution) {
      return true;
    }
    return false;
  }

  public getParameters(): ReportParameters {
    return this.parameters;
  }

  public getTypes(): ReportType[] {
    return this.types;
  }

  public getLogoTypes(): ReportType[] {
    return this.logoTypes;
  }

  public getSelectedEquipment(): Equipment[] {
    let equipment: Equipment[] = [];
    for(let i: number = 0; i < this.parameters.equipment.length; i++) {
      const selection: EquipmentSelection = this.parameters.equipment[i];
      if(selection.selected) {
        equipment.push(selection.equipment);
      }
    }
    return equipment;
  }

  public getEquipmentSelection(): EquipmentSelection[] {
    return this.parameters.equipment;
  }

  public getAllSelected(): boolean {
    for(let i: number = 0; i < this.parameters.equipment.length; i++) {
      const selection: EquipmentSelection = this.parameters.equipment[i];
      if(!selection.selected) {
        return false;
      }
    }
    return true;
  }

  public getSomeSelected(): boolean {
    if(this.getAllSelected()) {
      return false;
    }
    for(let i: number = 0; i < this.parameters.equipment.length; i++) {
      const selection: EquipmentSelection = this.parameters.equipment[i];
      if(selection.selected) {
        return true;
      }
    }
    return false;
  }

  public setAll(selected: boolean): void {
    this.parameters.equipment.forEach(s => s.selected = selected);
  }

  public export(): void {
    if(this.reportComponent) {
      this.reportComponent.export();
    }
  }

}
