import { Component } from '@angular/core';
import { Facility } from './models/facility';
import { Organization } from './models/organization';
import { InspectionPlan } from './models/inspection-plan';
import { User } from './models/user';
import { InspectionAlarm } from './models/inspection-alarm';
import { StateService } from './services/state.service';
import { ApiService } from './services/api.service';
import { FeedbackService } from './services/feedback.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kalor ECM';

  public filtered: boolean;

  constructor(
    public state: StateService,
    private api: ApiService,
    private feedback: FeedbackService
  ) {
    this.feedback.say('Authenticating...');
    this.filtered = true;
  }

  public logout(): void {
    this.api.logout();
  }

  public getCurrentUser(): User {
    return this.state.getCurrentUser();
  }

  public getOrganization(): Organization {
    if(this.getCurrentUser() && this.getCurrentUser().lastActiveOrganization) {
      return this.getCurrentUser().lastActiveOrganization;
    }
    return null;
  }

  public clearOrganization(): void {
    if(this.getCurrentUser()) {
      this.getCurrentUser().lastActiveOrganization = null;
    }
  }

  public getInspectionPlans(): InspectionPlan[] {
    const organization: Organization = this.getOrganization();
    if(organization) {
      return organization.getInspectionPlans();
    }
    return [];
  }

  public getFacilities(): Facility[] {
    const organization: Organization = this.getOrganization();
    if(organization) {
      return organization.facilities;
    }
    return [];
  }

  public getAlarms(): InspectionAlarm[] {
    const organization: Organization = this.getOrganization();
    if(organization) {
      return organization.getAlarms();
    }
    return [];
  }
}
