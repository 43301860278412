import { Component, OnInit, Input } from '@angular/core';
import { StateService } from '../../services/state.service';
import { Summarizable } from '../../summarizable';

@Component({
  selector: 'app-summarizable',
  templateUrl: './summarizable.component.html',
  styleUrls: ['./summarizable.component.scss']
})
export class SummarizableComponent implements OnInit {

  @Input() summarizable: Summarizable;

  constructor(
    private stateService: StateService
  ) { }

  ngOnInit(): void {
  }

  activateDetails(): void {
    this.stateService.setDetailItem(this.summarizable);
  }

}
