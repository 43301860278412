<mat-toolbar>
    <button mat-raised-button color="primary" (click)="this.export()">Export</button>
</mat-toolbar>
<div #reportElement *ngIf="this.report">
    <header>
        <h1>{{ this.report.title }}</h1>
        <img *ngIf="this.report.logo && this.report.logo.name == 'org-icon'" [src]="this.report.execution.inspectionPlan.facility.organization.getImageUrl()" />
    </header>
    <section>
        <header><h2>Inngangur</h2></header>
        <section>
            <header><h3>{{ this.report.introTitle }}</h3></header>
            <p>{{ this.report.intro }}</p>
            <section>
                <header><h4>Framkvæmd</h4></header>
                <p>{{ this.report.methodology }}</p>
            </section>
            <section>
                <header><h4>Flokkun mælinga</h4></header>
                <p>Hver mæling úr hitamyndavélinni er greind og stig hennar flokkað samvæmt eftirfarandi flokkunarstaðli.</p>
                <table>
                    <tr>
                        <th>Stig</th><th>Lýsing</th><th>Aðgerð</th><th>Litur</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Mældur hiti innan marka.</td>
                        <td>Engin þörf á aðgerðum.</td>
                        <td style="background-color: limegreen">Grænn</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Mældur hiti nálægt mörkum.</td>
                        <td>Æskilegt að fylgjast með búnaði og þróun hitastigs í næsta eftirliti.</td>
                        <td style="background-color: gold">Gulur</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Mældur hiti yfir mörkum.</td>
                        <td>Æskilegt að ráðast í viðhaldsaðgerðir á búnaði við fyrsta tækifæri.</td>
                        <td style="background-color: coral">Appelsínugulur</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Mældur hiti hættulega hátt yfir eðlilegum mörkum búnaðar.</td>
                        <td>Nauðsynlegt að ráðast í viðhaldsaðgerðir á búnaði strax.</td>
                        <td style="background-color: crimson">Rauður</td>
                    </tr>
                </table>
            </section>
        </section>
    </section>
    <section>
        <header><h2>Niðurstöður</h2></header>
        <section>
            <header><h3>Framkvæmd eftirlits</h3></header>
            <dl>
                <dt>Staðsetning</dt>
                <dd>{{ this.report.execution.inspectionPlan.facility.name }}</dd>
                <dt>Dagsetning</dt>
                <dd>{{ this.report.execution.startedAt }}</dd>
                <dt>Tímarammi eftirlits</dt>
                <dd>{{ this.report.execution.inspectionPlan.interval.name }}</dd>
                <dt>Framkvæmdaraðili</dt>
                <dd>{{ this.report.execution.user.name }}</dd>
            </dl>
        </section>
        <section>
            <header><h3>Niðurstöður hitaeftirlits</h3></header>
            <section *ngFor="let inspection of this.getInspections()">
                <header><h4><dl><dt>Heiti búnaðar</dt><dd>{{ inspection.equipment.name }}</dd></dl></h4></header>
                <dl>
                    <dt>Staða</dt>
                    <dd>{{ inspection.status.name }} - {{ inspection.status.description }}</dd>
                    <dt>Athugasemd</dt>
                    <dd>{{ inspection.notes }}</dd>
                </dl>
                <section *ngFor="let reading of inspection.getOrdered()">
                    <hr />
                    <dl>
                        <dt>Mæling</dt>
                        <dd>
                            <img class="reading-image" [src]="reading.getThermalScaledUrl()" />
                            <img class="reading-image" [src]="reading.getImageUrl()" />
                        </dd>
                        <dt>Staða mælingar</dt>
                        <dd>{{ reading.status.name }} - {{ reading.status.description }}</dd>
                        <dt>Dagsetning</dt>
                        <dd>{{ reading.recordedAt }}</dd>
                        <dt>Umhverfishitastig</dt>
                        <dd>{{ reading.ambientTemp | number:'1.0-0' }} °C</dd>
                        <dt>Hámarkshitastig</dt>
                        <dd>{{ reading.maxTemp | number:'1.0-0' }} °C</dd>
                        <dt>Aðgerðarstig</dt>
                        <dd>Stig {{ reading.getAlertLevel() }}</dd>
                        <dt>Athugasemd við mælingu</dt>
                        <dd>{{ reading.notes }}</dd>
                        <dt *ngIf="reading.validationUser">Staðfestingaraðili</dt>
                        <dd *ngIf="reading.validationUser">{{ reading.validationUser.name }}</dd>
                        <dt>Dagsetning staðfestingar</dt>
                        <dd>{{ reading.validationAt }}</dd>
                        <dt>Athugasemd við staðfestingu</dt>
                        <dd>{{ reading.validationNote }}</dd>
                    </dl>
                    <table *ngIf="reading.anomalies.length > 0">
                        <tr>
                            <th>Frávik</th>
                            <th>Staðsetning</th>
                            <th>Hitastig</th>
                            <th>Aðgerðarstig</th>
                            <th>Athugasemd</th>
                            <th>Aðgerð</th>
                        </tr>
                        <tr *ngFor="let anomaly of reading.anomalies; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>
                                <!--div class="preview">
                                    <div [ngStyle]="this.getPreviewStyle(anomaly)"></div>
                                    <div [ngStyle]="this.getThermalPreviewStyle(anomaly)"></div>
                                </div-->
                            </td>
                            <td>{{ anomaly.minTemp | number:'1.0-0' }} - {{ anomaly.maxTemp | number:'1.0-0' }} °C</td>
                            <td>Stig {{ anomaly.severity }}</td>
                            <td>{{ anomaly.validationNote }}</td>
                            <td>{{ anomaly.validationAdvice }}</td>
                        </tr>
                    </table>
                </section>
            </section>
        </section>
    </section>
    <section>
        <header><h2>Lokaorð</h2></header>
        <p>{{ this.report.conclusion }}</p>
    </section>
    <!--mat-drawer-container>
        <mat-drawer mode="side" position="end" opened>

        </mat-drawer>
        <mat-drawer-content>
            <img *ngIf="this.report.parameters.logo.name == 'org-icon'" [src]="this.report.parameters.execution.inspectionPlan.facility.organization.getImageUrl()" />
        </mat-drawer-content>
    </mat-drawer-container>
    <app-section *ngFor="let section of this.report.sections" [section]="section" [level]="0"></app-section-->
</div>