import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-level',
  templateUrl: './alert-level.component.html',
  styleUrls: ['./alert-level.component.scss']
})
export class AlertLevelComponent implements OnInit {

  @Input() level: number;
  @Input() amount: number;

  constructor() { }

  ngOnInit(): void {
  }

  public icon(): string {
    switch(+this.level) {
      case 1:
        return 'thumb_up';
      case 2:
      case 3:
        return 'error_outline';
      case 4:
        return 'warning';
    }
  }

  public color(): string {
    switch(+this.level) {
      case 1:
        return 'limegreen';
      case 2:
        return 'gold';
      case 3:
        return 'coral';
      case 4:
        return 'crimson';
    }
  }

  public getStyle(): any {
    return {
      'color': this.color()
    };
  }

}
