import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'auth-img',
  templateUrl: './auth-img.component.html',
  styleUrls: ['./auth-img.component.scss']
})
export class AuthImgComponent implements OnInit {

  @Input() src: string;
  @Input() alt = '';

  constructor() { }

  ngOnInit(): void {
  }

}
