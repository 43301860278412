import { Component, Input, OnInit } from '@angular/core';
import { Organization } from '../../models/organization';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  
  @Input() organization: Organization;

  constructor() { }

  ngOnInit(): void {
  }

}
