<mat-drawer-container *ngIf="this.section.title">
    <mat-drawer mode="side" position="end" opened>
        <button mat-button
            (click)="this.addSection()"
        >
            <mat-icon>add</mat-icon>
        </button>
        <button
            *ngIf="this.getEditingTitle()"
            mat-button
            (click)="this.toggleEditingTitle()"
        >
            <mat-icon>done</mat-icon>
        </button>
    </mat-drawer>
    <mat-drawer-content>
        <ng-container *ngIf="!this.getEditingTitle()">
            <ng-container *ngIf="this.level == 0">
                <h1 (click)="this.toggleEditingTitle()">
                    {{ this.section.title }}
                </h1>
            </ng-container>
            <ng-container *ngIf="this.level == 1">
                <h2 (click)="this.toggleEditingTitle()">
                    {{ this.section.title }}
                </h2>
            </ng-container>
            <ng-container *ngIf="this.level == 2">
                <h3 (click)="this.toggleEditingTitle()">
                    {{ this.section.title }}
                </h3>
            </ng-container>
            <ng-container *ngIf="this.level == 3">
                <h4 (click)="this.toggleEditingTitle()">
                    {{ this.section.title }}
                </h4>
            </ng-container>
            <ng-container *ngIf="this.level == 4">
                <h5 (click)="this.toggleEditingTitle()">
                    {{ this.section.title }}
                </h5>
            </ng-container>
            <ng-container *ngIf="this.level > 4">
                <p (click)="this.toggleEditingTitle()">
                    {{ this.section.title }}
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="this.getEditingTitle()">
            <form>
                <mat-form-field>
                    <mat-label>Section title</mat-label>
                    <input
                        matInput
                        [(ngModel)]="this.section.title"
                        name="title"
                    />
                </mat-form-field>
            </form>
        </ng-container>
    </mat-drawer-content>
</mat-drawer-container>
<mat-drawer-container *ngIf="this.section.body">
    <mat-drawer mode="side" position="end" opened>
        <button
            *ngIf="this.getEditingBody()"
            mat-button
            (click)="this.toggleEditingBody()"
        >
            <mat-icon>done</mat-icon>
        </button>
    </mat-drawer>
    <mat-drawer-content>
        <p
            *ngIf="!this.getEditingBody()"
            (click)="this.toggleEditingBody()"
        >{{ this.section.body }}</p>
        <ng-container *ngIf="this.getEditingBody()">
            <form>
                <mat-form-field>
                    <mat-label>Section body</mat-label>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        [(ngModel)]="this.section.body"
                        name="body"
                    ></textarea>
                </mat-form-field>
            </form>
        </ng-container>
    </mat-drawer-content>
</mat-drawer-container>
<app-section
    *ngFor="let section of this.section.sections"
    [section]="section"
    [level]="this.level + 1">
</app-section>