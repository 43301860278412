<div>
    <mat-card
        [ngStyle]="{'background-image': 'url(' + this.summarizable.getImageUrl() + ')', 'color': this.summarizable.getPrimaryTextColor()}"
        (click)="this.activateDetails()"
    >
        <mat-card-header>
            <mat-card-title>
                <span>{{ this.summarizable.getLabel() }}</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>{{ this.summarizable.getSubLabel() }}</span>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <app-alerts [alerts]="this.summarizable.getAlerts()"></app-alerts>
        </mat-card-content>
        <mat-card-footer>
            <app-facility-summary *ngIf="this.summarizable.getType() == 'facility'" [facility]="this.summarizable"></app-facility-summary>
            <app-inspection-plan-summary *ngIf="this.summarizable.getType() == 'inspection-plan'" [inspectionPlan]="this.summarizable"></app-inspection-plan-summary>
            <app-inspection-alarm-summary *ngIf="this.summarizable.getType() == 'inspection-alarm'" [alarm]="this.summarizable"></app-inspection-alarm-summary>
        </mat-card-footer>
    </mat-card>
</div>