<mat-card>
    <mat-card-header>
        <img mat-card-avatar [src]="this.facility.getImageUrl()" />
        <mat-card-title>{{ this.facility.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p *ngIf="this.facility.getSubLabel()">{{ this.facility.getSubLabel() }}</p>
    </mat-card-content>
    <mat-card-actions>
        <mat-checkbox color="primary" [(ngModel)]="this.editing"  (change)="this.processToggleEditing()" name="editing">Edit</mat-checkbox>
        <button mat-raised-button color="primary" *ngIf="this.editing" (click)="this.save()">Save</button>
        <button mat-button *ngIf="this.editing && this.facility.canDelete(this.state.getCurrentUser())" (click)="this.delete()">Delete</button>
    </mat-card-actions>
</mat-card>
<mat-tab-group #tabGroup>
    <mat-tab [label]="this.facility.getOverviewTitle()">
        <mat-accordion>
            <app-equipment-status *ngFor="let equipment of this.facility.equipment" [equipment]="equipment"></app-equipment-status>
        </mat-accordion>
    </mat-tab>
    <mat-tab label="Attributes">
        <mat-list *ngIf="!this.editing; else editingTemplate">
            <h3 matSubheader>Name</h3>
            <mat-list-item><h3 matLine>{{ this.facility.name }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Description</h3>
            <mat-list-item><h3 matLine>{{ this.facility.description }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Address</h3>
            <mat-list-item><h3 matLine>{{ this.facility.address }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Description</h3>
            <mat-list-item><h3 matLine>{{ this.facility.country }}</h3></mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
        <ng-template #editingTemplate>
            <form>
                <mat-list>
                    <h3 matSubheader>Name</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [(ngModel)]="this.facility.name" name="name" [value]="this.facility.name" />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Description</h3>
                    <mat-list-item>
                        <mat-form-field>
                            <textarea matInput [(ngModel)]="this.facility.description" name="description">{{ this.facility.description }}</textarea>
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Address</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [(ngModel)]="this.facility.address" name="address" [value]="this.facility.address" />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Country</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [(ngModel)]="this.facility.country" name="country" [value]="this.facility.country" />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-list>
                <h3 matSubheader>Image</h3>
                <app-image-upload matInput [(value)]="this.facility.imageLink"></app-image-upload>
            </form>
        </ng-template>
    </mat-tab>
</mat-tab-group>