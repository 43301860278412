import { Facility } from './facility';
import { Summarizable, DetailItem } from '../summarizable';
import { InspectionPlanExecution } from './inspection-plan-execution';
import { InspectionInterval } from './inspection-interval';
import { Equipment } from './equipment';
import { InspectionAlarm } from './inspection-alarm';
import { EquipmentType } from './equipment-type';
import { User } from './user';
import { Inspection } from './inspection';
import { InspectionReading } from './inspection-reading';

export class InspectionPlan implements Summarizable {
    id: number;
    name: string;
    facility: Facility;
    description: string;
    interval: InspectionInterval;
    nextInspection: Date;
    createdBy: User;
    createdAt: Date;

    executions: InspectionPlanExecution[];
    equipment: Equipment[];

    constructor(id: number, name: string, facility: Facility, description: string, interval: InspectionInterval, nextInspection: Date, createdBy: User, createdAt: Date) {
        this.id = id;
        this.name = name;
        this.facility = facility;
        this.description = description;
        this.interval = interval;
        this.nextInspection = nextInspection;
        this.createdBy = createdBy;
        this.createdAt = createdAt;

        this.executions = [];
        this.equipment = [];
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public addExecution(execution: InspectionPlanExecution): void {
        this.executions.push(execution);
    }

    public addEquipment(equipment: Equipment): void {
        this.equipment.push(equipment);
    }

    public getLabel(): string {
        return this.name;
    }

    public getSubLabel(): string {
        return 'Next inspection: ' + this.getNextInspectionBody();
    }

    private getOverdue(): number {
        const today: Date = new Date();
        const difference: number = Math.ceil((today.getTime() - this.nextInspection.getTime()) / (1000 * 60 * 60 * 24));
        if(difference > 0) {
            return difference;
        }
        return 0;
    }

    private formatDate(date: Date): string {
        return [date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear()].join('.');
    }

    private getNextInspectionBody(): string {
        if(this.getOverdue() > 0) {
            return this.formatDate(this.nextInspection) + ' - ' + this.getOverdue().toString() + ' days overdue';
        }
        return this.formatDate(this.nextInspection);
    }

    public getLastExecution(): InspectionPlanExecution {
        if(this.executions.length > 0) {
            return this.executions[this.executions.length - 1];
        }
        return null;
    }

    private getLastInspectionBody(): string[] {
        let execution: InspectionPlanExecution = this.getLastExecution();
        if(execution) {
            return [
                execution.user && 'Performed by ' + execution.user.name || 'Could not determine user',
                this.formatDate(execution.startedAt)
            ]
        }
        return [
            'No inspection has been performed'
        ];
    }

    private getEquipmentList(): string[] {
        let list: string[] = [];
        let types: EquipmentType[] = [];
        for(let i: number = 0; i < this.equipment.length; i++) {
            const equipment: Equipment = this.equipment[i];
            if(!types.includes(equipment.type)) {
                types.push(equipment.type);
            }
        }
        for(let i: number = 0; i < types.length; i++) {
            const type: EquipmentType = types[i];
            let str: string = type.type + ': ';
            let items: Equipment[] = [];
            for(let j: number = 0; j < this.equipment.length; j++) {
                const equipment: Equipment = this.equipment[j];
                if(equipment.type == type) {
                    items.push(equipment);
                }
            }
            str += items.length.toString();
            list.push(str);
        }
        return list;
    }

    public getDetails(): DetailItem[] {
        return [
            {
                title: 'Name',
                body: [this.name],
                warn: false
            },
            {
                title: 'Description',
                body: [this.description],
                warn: false
            },/*
            {
                title: 'Next inspection',
                body: [this.getNextInspectionBody()],
                warn: this.getOverdue() > 0 ? true : false
            },*/
            {
                title: 'Last inspection',
                body: this.getLastInspectionBody(),
                warn: false
            },
            {
                title: 'Inspection interval',
                body: [this.interval.name],
                warn: false
            },
            {
                title: 'Equipment',
                body: this.getEquipmentList(),
                warn: false
            }
        ];
    }

    public getAlerts(): number[] {
        let alerts: number[] = [0, 0, 0, 0];
        for(let i: number = 0; i < this.equipment.length; i++) {
            const equipment: Equipment = this.equipment[i];
            const eqAlerts: number[] = equipment.getAlerts(this);
            for(let j: number = 0; j < 4; j++) {
                alerts[j] += eqAlerts[j];
            }
        }
        return alerts;
    }

    public getImageUrl(): string {
        return this.facility.getImageUrl();
    }

    public getType(): string {
        return 'inspection-plan';
    }

    public getBackgroundColor(): string {
        return 'white';
    }

    public getPrimaryTextColor(): string {
        return 'black';
    }

    public getFacility(): Facility {
        return this.facility;
    }

    public getOverviewTitle(): string {
        return 'Inspections';
    }

    public getEquipment(): Equipment[] {
        return this.equipment;
    }

    public match(term: string): boolean {
        return(
            this.name.includes(term)
         || this.description.includes(term)
         || this.facility.match(term)
        );
    }

    public getAlarms(): InspectionAlarm[] {
        let alarms: InspectionAlarm[] = [];
        for(let i: number = 0; i < this.executions.length; i++) {
            const execution: InspectionPlanExecution = this.executions[i];
            alarms = alarms.concat(execution.getAlarms());
        }
        return alarms;
    }

    public relatedToUser(user: User): boolean {
        if(this.createdBy == user) {
            return true;
        }
        for(let i: number = 0; i < this.executions.length; i++) {
            const execution: InspectionPlanExecution = this.executions[i];
            if(execution.user == user) {
                return true;
            }
            for(let j: number = 0; j < execution.inspections.length; j++) {
                const inspection: Inspection = execution.inspections[j];
                for(let k: number = 0; k < inspection.readings.length; k++) {
                    const reading: InspectionReading = inspection.readings[k];
                    if(reading.validationUser == user) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public sortFunction(a: InspectionPlan, b: InspectionPlan): number {
        const aAlerts: number[] = a.getAlerts();
        const bAlerts: number[] = b.getAlerts();
        if(aAlerts[3] > 0 || bAlerts[3] > 0) {
            if(aAlerts[3] == 0) return -1;
            if(bAlerts[3] == 0) return 1;
        }
        if(aAlerts[2] > 0 || bAlerts[2] > 0) {
            if(aAlerts[2] == 0) return -1;
            if(bAlerts[2] == 0) return 1;
        }
        const aExec: InspectionPlanExecution = a.getLastExecution();
        const bExec: InspectionPlanExecution = b.getLastExecution();
        if(aExec && bExec) {
            if(!aExec.startedAt && !bExec.startedAt) return 0;
            if(!aExec.startedAt) return -1;
            if(!bExec.startedAt) return 1;
            if(aExec.startedAt > bExec.startedAt) return 1;
            return -1;
        }
        if(!aExec && !bExec) return 0;
        if(!aExec) return -1;
        return 1;
    }

    public canDelete(user: User): boolean {
        if(this.equipment.length == 0 && this.executions.length == 0) {
            return true;
        }
        return false;
    }
}
