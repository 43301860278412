import { Injectable } from '@angular/core';
import { Organization } from '../models/organization';
import { Summarizable } from '../summarizable';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private detailItem: Summarizable;
  private activePanel: string;
  private activeForm: string;
  private currentUser: User;

  constructor() {
    this.detailItem = null;
    this.activePanel = null;
    this.activeForm = null;
  }
  
  public getCurrentUser(): User {
    return this.currentUser;
  }

  public setCurrentUser(user: User): void {
    this.currentUser = user;
  }

  public setActiveOrganization(organization: Organization) {
    if(this.currentUser) {
      this.currentUser.lastActiveOrganization = organization;
    }
  }

  public getActiveOrganization(): Organization {
    if(this.currentUser) {
      return this.currentUser.lastActiveOrganization;
    }
    return null;
  }

  public getActivePanel(): string {
    return this.activePanel;
  }

  public setActivePanel(panel: string): void {
    this.detailItem = null;
    this.activePanel = panel;
  }

  public clearActivePanel(): void {
    this.activePanel = null;
  }

  public getDetailItem(): Summarizable {
    return this.detailItem;
  }

  public setDetailItem(item: Summarizable): void {
    this.detailItem = item;
  }

  public clearDetailItem(): void {
    this.detailItem = null;
  }

  public getActiveForm(): string {
    return this.activeForm;
  }

  public setActiveForm(form: string): void {
    this.activeForm = form;
  }

  public clearActiveForm(): void {
    this.activeForm = null;
  }

  public getLabel(): string {
    if(this.detailItem) {
      switch(this.detailItem.getType()) {
        case 'facility':
          return 'Facility details';
        case 'equipment':
          return 'Equipment details';
        case 'inspection-plan':
          return 'Inspection plan details';
        case 'inspection-alarm':
          return 'Inspection alarm details';
      }
    }
    switch(this.activePanel) {
      case 'inspection-plans':
        return 'Inspection plans';
      case 'facilities':
        return 'Facilities';
      case 'alerts':
        return 'Alerts';
    }
  }
}
