<div class="reading">
    <section>
        <h2>Analysis results</h2>
        <br />
        <dl class="profile" *ngIf="this.reading.baselineReading.profile; else checkSuperProfile">
            <dt style="color: gold"><mat-icon>warning</mat-icon></dt>
            <dd>> {{ this.reading.baselineReading.profile.thresholds[0] | number:'1.0-0' }} °C</dd>
            <dt style="color: coral"><mat-icon>warning</mat-icon></dt>
            <dd>> {{ this.reading.baselineReading.profile.thresholds[1] | number:'1.0-0' }} °C</dd>
            <dt style="color: crimson"><mat-icon>warning</mat-icon></dt>
            <dd>> {{ this.reading.baselineReading.profile.thresholds[2] | number:'1.0-0' }} °C</dd>
        </dl>
        <ng-template #checkSuperProfile>
            <dl class="profile" *ngIf="this.reading.baselineReading.baseline.profile">
                <dt style="color: gold"><mat-icon>warning</mat-icon></dt>
                <dd>> {{ this.reading.baselineReading.baseline.profile.thresholds[0] | number:'1.0-0' }} °C</dd>
                <dt style="color: coral"><mat-icon>warning</mat-icon></dt>
                <dd>> {{ this.reading.baselineReading.baseline.profile.thresholds[1] | number:'1.0-0' }} °C</dd>
                <dt style="color: crimson"><mat-icon>warning</mat-icon></dt>
                <dd>> {{ this.reading.baselineReading.baseline.profile.thresholds[2] | number:'1.0-0' }} °C</dd>
            </dl>
        </ng-template>
        <dl class="results">
            <dt>Ambient temperature</dt>
            <dd>{{ this.reading.ambientTemp | number:'1.0-0' }} °C</dd>
            <dt>Maximum temperature</dt>
            <dd>{{ this.reading.maxTemp | number:'1.0-0' }} °C</dd>
            <dt>Emissivity</dt>
            <dd>{{ this.reading.baselineReading.emissivity }} %</dd>
        </dl>
        <article *ngIf="this.reading.notes">
            <h3>Notes</h3>
            <p>{{ this.reading.notes }}</p>
        </article>
    </section>
    <section>
        <h2>Imagery</h2>
        <div class="reading-image">
            <div *ngFor="let anomaly of this.reading.anomalies" [ngStyle]="this.anomalyFrameStyle(anomaly)"></div>
            <mat-button-toggle-group color="primary" multiple="true">
                <mat-button-toggle [checked]="this.getThermalMode()" (change)="this.setThermalMode($event.source.checked)" matTooltip="Show thermal image" matTooltipPosition="below"><mat-icon color="warn">whatshot</mat-icon></mat-button-toggle>
                <mat-button-toggle [checked]="this.getZoomMode()" (change)="this.setZoomMode($event.source.checked)" matTooltip="Zoom in" matTooltipPosition="below"><mat-icon>zoom_in</mat-icon></mat-button-toggle>
            </mat-button-toggle-group>
            <lib-ngx-image-zoom *ngIf="this.getZoomMode()" [thumbImage]="this.getImageUrl()" [fullImage]="this.getImageUrl()" enableScrollZoom="true" enableLens="true" circularLens="true" minZoomRatio="0.25" maxZoomRatio="8"></lib-ngx-image-zoom>
            <img *ngIf="!this.getZoomMode()" [src]="this.getImageUrl()" />
        </div>
    </section>
    <section *ngIf="this.reading.anomalies.length > 0">
        <h2>Anomalies</h2>
        <mat-horizontal-stepper labelPosition="bottom">
            <mat-step *ngFor="let anomaly of this.reading.getAnomaliesSorted(); let i = index">
                <ng-template matStepLabel>
                    <span (mouseover)="this.highlightAnomaly(anomaly)" (mouseout)="this.unhighlightAnomaly(anomaly)">
                        <mat-icon [ngStyle]="{'color': anomaly.getSeverityColor()}">warning</mat-icon>
                    </span>
                </ng-template>
                <div (mouseover)="this.highlightAnomaly(anomaly)" (mouseout)="this.unhighlightAnomaly(anomaly)">
                    <h3><span style="color: darkslategray">{{ anomaly.minTemp | number:'1.0-0' }} °C</span> - <span [ngStyle]="{'color': anomaly.getSeverityColor()}">{{ anomaly.maxTemp | number:'1.0-0' }} °C</span></h3>
                    <br />
                    <mat-form-field>
                        <mat-label>Severity</mat-label>
                        <mat-select [(value)]="anomaly.severity" name="severity">
                            <mat-select-trigger>
                                <mat-icon [ngStyle]="{'color': this.getSeverity(anomaly.severity).color}">warning</mat-icon>
                                <span>{{ this.getSeverity(anomaly.severity).name }}</span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let severity of this.getSeverities()" [value]="severity.severity">
                                <mat-icon [ngStyle]="{'color': severity.color}">warning</mat-icon>
                                <span>{{ severity.name }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br />
                    <mat-form-field>
                        <mat-label>Notes</mat-label>
                        <textarea matInput [(ngModel)]="anomaly.validationNote"></textarea>
                    </mat-form-field>
                    <br />
                    <mat-form-field>
                        <mat-label>Recommended course of action</mat-label>
                        <textarea matInput [(ngModel)]="anomaly.validationAdvice"></textarea>
                    </mat-form-field>
                    <br />
                    <div>
                        <button *ngIf="i > 0" mat-button matStepperPrevious type="button">Previous anomaly</button>
                        <button *ngIf="i < this.reading.anomalies.length - 1" mat-button matStepperNext type="button">Next anomaly</button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </section>
    <section>
        <h2>Validate analysis</h2>
        <ng-container *ngIf="(!this.reading.validationAt || !this.reading.validationUser); else validated">
            <mat-form-field>
                <mat-label>Notes</mat-label>
                <textarea matInput [(ngModel)]="this.reading.validationNote"></textarea>
            </mat-form-field>
            <div>
                <button mat-raised-button cdkStepperPrevious>Previous</button>
                <button mat-raised-button cdkStepperNext (click)="this.validateReading(this.reading)" color="primary">Validate</button>
                <button mat-raised-button cdkStepperNext>Skip</button>
            </div>
        </ng-container>
        <ng-template #validated>
            <div>Reading was validated by {{ this.reading.validationUser.name }} at {{ this.reading.validationAt }}</div>
            <div *ngIf="this.reading.validationNote">Note: {{ this.reading.validationNote }}</div>
            <div>
                <button mat-raised-button cdkStepperPrevious>Previous</button>
                <button mat-raised-button cdkStepperNext>Next</button>
            </div>
        </ng-template>
    </section>
</div>