<mat-card>
    <mat-card-header>
        <img mat-card-avatar [src]="this.item.getFacility().getImageUrl()" />
        <mat-card-title>{{ this.item.getLabel() }}</mat-card-title>
        <mat-card-subtitle>
            <span>{{ this.item.getFacility().name }}</span>
            <span *ngIf="item.getType() == 'inspection-plan'"> - {{ this.getIntervalString() }}</span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p *ngIf="this.item.getSubLabel()">{{ this.item.getSubLabel() }}</p>
    </mat-card-content>
</mat-card>
