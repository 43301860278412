import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Equipment } from 'src/app/models/equipment';
import { InspectionPlan } from 'src/app/models/inspection-plan';
import { InspectionPlanExecution } from 'src/app/models/inspection-plan-execution';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { StateService } from 'src/app/services/state.service';
import { DeletionComponent } from '../deletion/deletion.component';
import { EquipmentSelection } from '../inspection-plan-form/inspection-plan-form.component';

@Component({
  selector: 'app-inspection-plan',
  templateUrl: './inspection-plan.component.html',
  styleUrls: ['./inspection-plan.component.scss']
})
export class InspectionPlanComponent implements OnInit {

  @Input() plan: InspectionPlan;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  public activeExecution: InspectionPlanExecution;
  public editing: boolean;
  public selection: EquipmentSelection[];

  constructor(
    public state: StateService,
    public cache: CacheService,
    public api: ApiService,
    public dialog: MatDialog
  ) {
    this.editing = false;
    this.selection = [];
  }

  ngOnInit(): void {
    this.activeExecution = this.plan.getLastExecution();
    this.refreshSelection();
  }

  public refreshSelection(): void {
    this.selection = [];
    if(this.plan.facility) {
      const allEquipment: Equipment[] = this.plan.facility.equipment;
      for(let i: number = 0; i < allEquipment.length; i++) {
        const equipment: Equipment = allEquipment[i];
        let selected: boolean = false;
        if(this.plan.equipment.includes(equipment)) {
          selected = true;
        }
        this.selection.push({equipment: equipment, selected: selected});
      }
    }
  }

  public processToggleEditing(): void {
    if(this.editing) {
      this.showAttributes();
    }
  }

  public showAttributes(): void {
    this.tabGroup.selectedIndex = 1;
  }

  public getIntervalString(): string {
    return this.plan.interval.name + ' interval';
  }

  public save(): void {
    this.api.updateInspectionPlan(this.plan);
    this.editing = false;
  }

  private processDelete(result: boolean): void {
    if(result) {
      this.state.clearDetailItem();
      this.api.deleteInspectionPlan(this.plan);
    }
  }

  public delete(): void {
    let dialogRef: MatDialogRef<DeletionComponent> = this.dialog.open(DeletionComponent);
    dialogRef.afterClosed().subscribe(this.processDelete.bind(this));
  }

}
