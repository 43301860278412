<mat-horizontal-stepper linear="true">
    <mat-step label="Add facility">
        <form>
            <app-image-upload matInput [(value)]="this.facility.imageLink"></app-image-upload>
            <br />
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="this.facility.name" name="name" type="text" />
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput [(ngModel)]="this.facility.description" name="description"></textarea>
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput [(ngModel)]="this.facility.address" name="location" type="text" />
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput [(ngModel)]="this.facility.country" name="country" type="text" />
            </mat-form-field>
            <br />
        </form>
        <button mat-raised-button (click)="this.save()" color="primary">Save</button>
        <button mat-raised-button (click)="this.stateService.clearActiveForm()">Cancel</button>
    </mat-step>
</mat-horizontal-stepper>