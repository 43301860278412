import { Organization } from './organization';
import { InspectionPlanExecution } from './inspection-plan-execution';
import { InspectionAlarm } from './inspection-alarm';
import { InspectionReading } from './inspection-reading';

export class User {
    id: number;
    name: string;
    email: string;
    authKey: string;
    imageLink: string;
    lastActiveOrganization: Organization;
    active: boolean;
    phone1: string;
    phone2: string;

    inspectionPlanExecutions: InspectionPlanExecution[];
    inspectionReadingValidations: InspectionReading[];
    inspectionAlarmsDeactivated: InspectionAlarm[];
    organizations: Organization[];

    constructor(
        id: number,
        name: string,
        email: string,
        authKey: string,
        lastActiveOrganization: Organization,
        imageLink: string,
        active: boolean,
        phone1: string,
        phone2: string
    ) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.authKey = authKey;
        this.lastActiveOrganization = lastActiveOrganization;
        this.imageLink = imageLink;
        this.active = active;
        this.phone1 = phone1;
        this.phone2 = phone2;

        this.lastActiveOrganization = null;
        this.inspectionPlanExecutions = [];
        this.inspectionReadingValidations = [];
        this.inspectionAlarmsDeactivated = [];
        this.organizations = [];
    }

    public addOrganization(organization: Organization): void {
        this.organizations.push(organization);
    }

    public addInspectionPlanExecution(execution: InspectionPlanExecution): void {
        this.inspectionPlanExecutions.push(execution);
    }

    public addInspectionPlanReadingValidation(reading: InspectionReading): void {
        this.inspectionReadingValidations.push(reading);
    }

    public deactivateInspectionAlarm(alarm: InspectionAlarm): void {
        this.inspectionAlarmsDeactivated.push(alarm);
    }
}
