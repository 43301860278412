<mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <img class="icon" [src]="this.inspection.equipment.getImageUrl()" />
            {{ this.inspection.equipment.name }}
        </mat-panel-title>
        <mat-panel-description>
            <span>{{ this.inspection.execution.startedAt | date:'d. MMM. yyyy HH:mm' }}</span>
            <span>{{ this.inspection.status.name }}</span>
            <span mat-line class="critical issues" *ngIf="this.inspection.getAlerts()[3] > 0">{{ this.inspection.getAlerts()[3] }} Critical issues active</span>
            <span mat-line class="high-risk issues" *ngIf="this.inspection.getAlerts()[2] > 0">{{ this.inspection.getAlerts()[2] }} High risk issues active</span>
            <span mat-line class="low-risk issues" *ngIf="this.inspection.getAlerts()[1] > 0">{{ this.inspection.getAlerts()[1] }} Low risk issues active</span>
            <span mat-line class="no issues" *ngIf="this.getNoIssuesDetected()">No issues detected</span>
            <span mat-line class="no issues" *ngIf="this.getNoActiveIssues()">No active issues</span>
            <mat-icon [ngClass]="this.inspection.getValidated() ? 'verified' : 'unverified'">verified</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <app-baseline-stepper *ngIf="this.inspection.equipment.getActiveBaseline()" [inspection]="this.inspection">
        <cdk-step *ngFor="let reading of this.inspection.getOrdered()">
            <app-inspection-reading *ngIf="reading" [reading]="reading"></app-inspection-reading>
        </cdk-step>
    </app-baseline-stepper>
</mat-expansion-panel>