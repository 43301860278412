import { InspectionPlan } from './inspection-plan';
import { User } from './user';
import { InspectionStatus } from './inspection-status';
import { Inspection } from './inspection';
import { InspectionAlarm } from './inspection-alarm';

export class InspectionPlanExecution {
    id: number;
    name: string;
    inspectionPlan: InspectionPlan;
    status: InspectionStatus;
    user: User;
    notes: string;
    startedAt: Date;
    validationNotes: string;

    inspections: Inspection[];

    constructor(id: number, name: string, inspectionPlan: InspectionPlan, status: InspectionStatus, user: User, notes: string, startedAt: Date, validationNotes: string) {
        this.id = id;
        this.name = name;
        this.inspectionPlan = inspectionPlan;
        this.status = status;
        this.user = user;
        this.notes = notes;
        this.startedAt = startedAt;
        this.validationNotes = validationNotes;

        this.inspections = [];
    }

    public addInspection(inspection: Inspection): void {
        this.inspections.push(inspection);
    }

    public getAlarms(): InspectionAlarm[] {
        let alarms: InspectionAlarm[] = [];
        for(let i: number = 0; i < this.inspections.length; i++) {
            const inspection: Inspection = this.inspections[i];
            alarms = alarms.concat(inspection.getAlarms());
        }
        return alarms;
    }

    public getAlerts(): number[] {
        let alerts: number[] = [0, 0, 0, 0];
        for(let i: number = 0; i < this.inspections.length; i++) {
            const inspection: Inspection = this.inspections[i];
            const newAlerts: number[] = inspection.getAlerts();
            for(let j: number = 0; j < 4; j++) {
                alerts[j] += newAlerts[j];
            }
        }
        return alerts;
    }

    public getValidated(): boolean {
        for(let i: number = 0; i < this.inspections.length; i++) {
            const inspection: Inspection = this.inspections[i];
            if(!inspection.getValidated()) {
                return false;
            }
        }
        return true;
    }
}
