<mat-horizontal-stepper *ngIf="this.plan" linear="true">
    <mat-step label="Report parameters">
        <form>
            <mat-form-field>
                <mat-label>Execution</mat-label>
                <mat-select [(ngModel)]="this.report.execution" name="execution">
                    <mat-option *ngFor="let execution of this.plan.executions" [value]="execution">{{ execution.startedAt | date:'dd.MM.yyyy HH:mm' }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Report type</mat-label>
                <mat-select [(ngModel)]="this.report.type" name="type">
                    <mat-option *ngFor="let type of this.getTypes()" [value]="type">{{ type.description }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Report title</mat-label>
                <input matInput type="text" [(ngModel)]="this.report.title" name="title" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Report logo</mat-label>
                <mat-select>
                    <mat-option *ngFor="let type of this.getLogoTypes()" [value]="type">{{ type.description }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Introduction title</mat-label>
                <input matInput type="text" [(ngModel)]="this.report.introTitle" name="introTitle" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Introduction</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [(ngModel)]="this.report.intro" name="intro"></textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Methodology</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [(ngModel)]="this.report.methodology" name="methodology">{{ this.report.methodology }}</textarea>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Conclusion</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [(ngModel)]="this.report.conclusion" name="conclusion">{{ this.report.conclusion }}</textarea>
            </mat-form-field>
            <section class="section">
                <div>
                    <mat-checkbox class="margin" [checked]="this.getAllSelected()" [indeterminate]="this.getSomeSelected()" (change)="this.setAll($event.checked)">All equipment</mat-checkbox>
                </div>
                <div>
                    <ul>
                        <li *ngFor="let selection of this.getEquipmentSelection()">
                            <mat-checkbox [(ngModel)]="selection.selected" [name]="selection.equipment.name">{{ selection.equipment.name }}</mat-checkbox>
                        </li>
                    </ul>
                </div>
            </section>
        </form>
        <button mat-raised-button (click)="this.stateService.clearActiveForm()">Cancel</button>
    </mat-step>
    <mat-step label="Preview">
        <app-report #reportComponent class="report" *ngIf="this.hasReport()" [report]="this.report" [equipment]="this.getSelectedEquipment()"></app-report>
    </mat-step>
</mat-horizontal-stepper>