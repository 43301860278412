export class InspectionInterval {
    id: number;
    name: string;
    interval: number;

    constructor(id: number, name: string, interval: number) {
        this.id = id;
        this.name = name;
        this.interval = interval;
    }

}
