import { User } from './user';
import { Facility } from './facility';
import { AnalysisProfile } from './analysis-profile';
import { environment } from 'src/environments/environment';
import { InspectionPlan } from './inspection-plan';
import { InspectionAlarm } from './inspection-alarm';

export class Organization {

    id: number;
    name: string;
    description: string;
    imageLink: string;
    active: boolean;

    users: User[];
    facilities: Facility[];
    profiles: AnalysisProfile[];

    constructor(id: number, name: string, description: string, imageLink: string, active: boolean) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.imageLink = imageLink;
        this.active = active;

        this.users = [];
        this.facilities = [];
        this.profiles = [];
    }

    public addUser(user: User): void {
        this.users.push(user);
    }

    public addFacility(facility: Facility): void {
        this.facilities.push(facility);
    }

    public removeFacility(facility: Facility): void {
        const index: number = this.facilities.indexOf(facility);
        if(index > -1) {
            this.facilities.splice(index, 1);
        }
    }

    public addProfile(profile: AnalysisProfile): void {
        this.profiles.push(profile);
    }

    public getImageUrl(): string {
        if(this.imageLink) {
            return environment.imageUrl + this.imageLink;
        }
        return '';
    }

    public getInspectionPlans(): InspectionPlan[] {
        let plans: InspectionPlan[] = [];
        for(let i: number = 0; i < this.facilities.length; i++) {
            const facility: Facility = this.facilities[i];
            plans = plans.concat(facility.inspectionPlans);
        }
        return plans;
    }

    public getAlarms(): InspectionAlarm[] {
        let alarms: InspectionAlarm[] = [];
        for(let i: number = 0; i < this.facilities.length; i++) {
            const facility: Facility = this.facilities[i];
            alarms = alarms.concat(facility.getAlarms());
        }
        return alarms;
    }
}
