<mat-icon>business</mat-icon>
<div class="description">
    {{ this.inspectionPlan.facility.getLabel() }}
</div>
<br />
<ng-container *ngIf="this.inspectionPlan.facility.getSubLabel().length > 0">
    <mat-icon>location_on</mat-icon>
    <div class="address">
        {{ this.inspectionPlan.facility.getSubLabel() }}
    </div>
</ng-container>