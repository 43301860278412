import { Component, OnInit, Input } from '@angular/core';
import { InspectionAlarm } from '../../models/inspection-alarm';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() inspectionAlarm: InspectionAlarm;

  constructor() { }

  ngOnInit(): void {
  }

}
