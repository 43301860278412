import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from 'src/app/models/equipment';
import { Facility } from 'src/app/models/facility';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss']
})
export class EquipmentFormComponent implements OnInit {

  public equipment: Equipment;

  @Input() facility: Facility;

  constructor(
    public state: StateService,
    public cache: CacheService,
    public api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.equipment = new Equipment(null, '', null, '', this.facility, '', '', this.state.getCurrentUser(), new Date());
  }

  public save(): void {
    this.api.addEquipment(this.equipment);
    this.state.clearActiveForm();
  }

}
