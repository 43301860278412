<mat-horizontal-stepper linear="true">
    <mat-step label="Add equipment">
        <form>
            <app-image-upload matInput [(value)]="this.equipment.imageLink"></app-image-upload>
            <br />
            <mat-form-field>
                <mat-label>Facility</mat-label>
                <mat-select [(ngModel)]="this.equipment.facility" name="facility">
                    <mat-option *ngFor="let facility of this.state.getActiveOrganization().facilities" [value]="facility">{{ facility.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="this.equipment.type" name="type">
                    <mat-option *ngFor="let type of this.cache.getEquipmentTypes()" [value]="type">{{ type.type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="this.equipment.name" name="name" />
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Location</mat-label>
                <input matInput type="text" [(ngModel)]="this.equipment.location" name="location" />
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput [(ngModel)]="this.equipment.description" name="description"></textarea>
            </mat-form-field>
            <br />
        </form>
        <button mat-raised-button (click)="this.save()" color="primary">Save</button>
        <button mat-raised-button (click)="this.state.clearActiveForm()" target="_blank">Cancel</button>
    </mat-step>
</mat-horizontal-stepper>