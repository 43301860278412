import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService } from '../../services/api.service';
import { Organization } from '../../models/organization';
import { StateService } from '../../services/state.service';
import { User } from '../../models/user';
import { InspectionPlan } from 'src/app/models/inspection-plan';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  public nextFiltered: boolean;
  public topFiltered: boolean;

  constructor(
    private state: StateService,
    private api: ApiService
  ) {
    this.nextFiltered = true;
    this.topFiltered = true;
  }

  public logout(): void {
    this.api.logout();
  }

  public activateInspectionPlans(): void {
    this.state.setActivePanel('inspection-plans');
  }

  public activateFacilities(): void {
    this.state.setActivePanel('facilities');
  }

  public activateAlerts(): void {
    this.state.setActivePanel('alerts');
  }

  public getCurrentUser(): User {
    return this.state.getCurrentUser();
  }

  public getOrganization(): Organization {
    if(this.getCurrentUser() && this.getCurrentUser().lastActiveOrganization) {
      return this.getCurrentUser().lastActiveOrganization;
    }
    return null;
  }

  public clearOrganization(): void {
    if(this.getCurrentUser()) {
      this.getCurrentUser().lastActiveOrganization = null;
    }
  }

  public getOrganizations(): Organization[] {
    if(this.state.getCurrentUser()) {
      return this.state.getCurrentUser().organizations;
    }
    return [];
  }

  public getNextInspectionPlans(): InspectionPlan[] {
    const organization: Organization = this.getOrganization();
    if(organization && organization.getInspectionPlans().length > 0) {
      let plans: InspectionPlan[] = organization.getInspectionPlans().sort((a: InspectionPlan, b: InspectionPlan) => {
        if(a.nextInspection > b.nextInspection) {
          return 1;
        }
        if(b.nextInspection > a.nextInspection) {
          return -1;
        }
        return 0;
      });
      return plans;
    }
    return [];
  }

  public getOutstandingInspectionPlans(): InspectionPlan[] {
    const organization: Organization = this.getOrganization();
    const outPlans: InspectionPlan[] = [];
    if(organization && organization.getInspectionPlans().length > 0) {
      let plans: InspectionPlan[] = organization.getInspectionPlans();
      const anyPlan: InspectionPlan = plans[0];
      plans = plans.sort(anyPlan.sortFunction).reverse();
      for(let i: number = 0; i < plans.length; i++) {
        const plan: InspectionPlan = plans[i];
        if(plan.getAlerts()[2] > 0 || plan.getAlerts()[3] > 0) {
          outPlans.push(plan);
        }
      }
    }
    return outPlans;
  }

  ngOnInit(): void {
  }

}
