import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '../../models/equipment';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-equipment-status',
  templateUrl: './equipment-status.component.html',
  styleUrls: ['./equipment-status.component.scss']
})
export class EquipmentStatusComponent implements OnInit {

  @Input() equipment: Equipment;

  constructor(
    private stateService: StateService
  ) { }

  ngOnInit(): void {
  }

  public getBorderColor(): string {
    switch(this.equipment.getAlertLevel()) {
      case 0:
          return 'limegreen';
      case 1:
          return 'gold';
      case 2:
          return 'coral';
      case 3:
          return 'crimson';
    }
  }

  public getBackground(): string {
    return 'url(\"' + this.equipment.getImageUrl() + '\")';
  }

  public getNoIssuesDetected(): boolean {
    const alerts: number[] = this.equipment.getAlerts();
    return(
        alerts[3] + alerts[2] + alerts[1] == 0
     && alerts[0] > 0
    );
  }

  public getNoActiveIssues(): boolean {
    const alerts: number[] = this.equipment.getAlerts();
    for(let i: number = 0; i < 4; i++) {
      if(alerts[i] > 0) {
        return false;
      }
    }
    return true;
  }

  public getIssueClass(): string {
    switch(this.equipment.getAlertLevel()) {
      case 0:
        return 'no issues';
      case 1:
        return 'low-risk issues'
      case 2:
        return 'high-risk issues'
      case 3:
        return 'critical issues'
    }
  }

  public getIssueInfo(): string {
    if(this.equipment.getAlertLevel() == 0) {
      return 'No active issues';
    }
    const out: string[] = [];
    const alerts: number[] = this.equipment.getAlerts();
    if(alerts[3] > 0) {
      out.push(alerts[3].toString() + ' critical issues');
    }
    if(alerts[2] > 0) {
      out.push(alerts[2].toString() + ' high-risk issues');
    }
    if(alerts[1] > 0) {
      out.push(alerts[1].toString() + ' low-risk issues');
    }
    return out.join(', ');
  }

  public getIcon(): string {
    if(this.equipment.getAlertLevel() > 0) {
      return 'warning';
    }
    return 'info';
  }

  public activateDetails(): void {
    this.stateService.setDetailItem(this.equipment);
  }

}
