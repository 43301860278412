<mat-card>
    <mat-card-header>
        <img mat-card-avatar [src]="this.plan.facility.getImageUrl()" />
        <mat-card-title>
            {{ this.plan.name }}
        </mat-card-title>
        <mat-card-subtitle>
            {{ this.plan.facility.name }} - {{ this.getIntervalString() }}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p *ngIf="this.plan.getSubLabel()">{{ this.plan.getSubLabel() }}</p>
    </mat-card-content>
    <mat-card-actions>
        <mat-checkbox color="primary" [(ngModel)]="this.editing" (change)="this.processToggleEditing()" name="editing">Edit</mat-checkbox>
        <button mat-raised-button color="primary" *ngIf="this.editing" (click)="this.save()">Save</button>
        <button mat-button *ngIf="this.editing && this.plan.canDelete(this.state.getCurrentUser())" (click)="this.delete()">Delete</button>
    </mat-card-actions>
</mat-card>
<mat-tab-group #tabGroup>
    <mat-tab [label]="this.plan.getOverviewTitle()">
        <mat-card>
            <mat-card-content>
                <mat-form-field>
                    <mat-label>Execution</mat-label>
                    <select matNativeControl [(ngModel)]="this.activeExecution">
                        <option matOption *ngFor="let execution of this.plan.executions" [ngValue]="execution">{{ execution.startedAt }}</option>
                    </select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-accordion *ngIf="this.activeExecution">
            <app-inspection *ngFor="let inspection of this.activeExecution.inspections" [inspection]="inspection"></app-inspection>
        </mat-accordion>
    </mat-tab>
    <mat-tab label="Attributes">
        <mat-list *ngIf="!this.editing; else editingTemplate">
            <h3 matSubheader>Facility</h3>
            <mat-list-item><h3 matLine>{{ this.plan.facility.name }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Name</h3>
            <mat-list-item><h3 matLine>{{ this.plan.name }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Description</h3>
            <mat-list-item><h3 matLine>{{ this.plan.description }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Interval</h3>
            <mat-list-item><h3 matLine>{{ this.plan.interval.name }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Next inspection</h3>
            <mat-list-item><h3 matLine>{{ this.plan.nextInspection | date:'d. MMM. yyyy HH:mm' }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Equipment</h3>
            <mat-list-item *ngFor="let equipment of this.plan.equipment">{{ equipment.name }}</mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
        <ng-template #editingTemplate>
            <form>
                <mat-list>
                    <h3 matSubheader>Facility</h3>
                    <mat-list-item>
                        <mat-select [(ngModel)]="this.plan.facility" (selectionChange)="this.refreshSelection()" name="facility">
                            <mat-option *ngFor="let facility of this.state.getActiveOrganization().facilities" [value]="facility">{{ facility.name }}</mat-option>
                        </mat-select>
                    </mat-list-item>
                    <h3 matSubheader>Name</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [(ngModel)]="this.plan.name" name="name" [value]="this.plan.name" />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Description</h3>
                    <mat-list-item>
                        <mat-form-field>
                            <textarea matInput [(ngModel)]="this.plan.description" name="description">{{ this.plan.description }}</textarea>
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Interval</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <mat-select [(ngModel)]="this.plan.interval" name="interval">
                                <mat-option *ngFor="let interval of this.cache.getInspectionIntervals()" [value]="interval">{{ interval.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Next inspection</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="this.plan.nextInspection" name="nextInspection">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </mat-list-item>
                    <!--mat-divider></mat-divider>
                    <h3 matSubheader>Equipment</h3>
                    <mat-list-item *ngFor="let selection of this.selection">
                        <mat-checkbox color="primary" [(ngModel)]="selection.selected" [name]="selection.equipment.id">{{ selection.equipment.name }}</mat-checkbox>
                    </mat-list-item>
                    <mat-divider></mat-divider-->
                </mat-list>
            </form>
        </ng-template>
    </mat-tab>
</mat-tab-group>