import { Component, OnInit } from '@angular/core';
import { Equipment } from 'src/app/models/equipment';
import { InspectionPlan } from 'src/app/models/inspection-plan';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { StateService } from '../../services/state.service';


export interface EquipmentSelection {
  equipment: Equipment,
  selected: boolean
}


@Component({
  selector: 'app-inspection-plan-form',
  templateUrl: './inspection-plan-form.component.html',
  styleUrls: ['./inspection-plan-form.component.scss']
})
export class InspectionPlanFormComponent implements OnInit {

  public plan: InspectionPlan;

  public selection: EquipmentSelection[];

  constructor(
    public state: StateService,
    public cache: CacheService,
    public api: ApiService
  ) {
    this.selection = [];
  }

  ngOnInit(): void {
    this.plan = new InspectionPlan(null, '', null, '', null, null, this.state.getCurrentUser(), new Date());
  }

  public refreshSelection(): void {
    this.selection = [];
    if(this.plan.facility) {
      const allEquipment: Equipment[] = this.plan.facility.equipment;
      for(let i: number = 0; i < allEquipment.length; i++) {
        const equipment: Equipment = allEquipment[i];
        this.selection.push({equipment: equipment, selected: false});
      }
    }
  }

  public save(): void {
    this.api.addInspectionPlan(this.plan, this.selection);
    this.state.clearActiveForm();
  }

}
