<mat-horizontal-stepper linear="true">
    <mat-step label="New inspection plan">
        <form>
            <mat-form-field>
                <mat-label>Facility</mat-label>
                <mat-select [(ngModel)]="this.plan.facility" (selectionChange)="this.refreshSelection()" name="facility">
                    <mat-option *ngFor="let facility of this.state.getActiveOrganization().facilities" [value]="facility">{{ facility.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="this.plan.name" name="name" />
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput [(ngModel)]="this.plan.description" name="description"></textarea>
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Inspection interval</mat-label>
                <mat-select [(ngModel)]="this.plan.interval" name="interval">
                    <mat-option *ngFor="let interval of this.cache.getInspectionIntervals()" [value]="interval">{{ interval.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field>
                <mat-label>Initial inspection date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="this.plan.nextInspection" name="nextInspection">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <br />
            <span *ngIf="this.plan.facility">
                <div *ngFor="let selection of this.selection">
                    <mat-checkbox color="primary" [(ngModel)]="selection.selected" [name]="selection.equipment.id">{{ selection.equipment.name }}</mat-checkbox>
                </div>
            </span>
        </form>
        <button mat-raised-button (click)="this.save()" color="primary">Save</button>
        <button mat-raised-button (click)="this.state.clearActiveForm()">Cancel</button>
    </mat-step>
</mat-horizontal-stepper>