import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Equipment } from 'src/app/models/equipment';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { StateService } from 'src/app/services/state.service';
import { DeletionComponent } from '../deletion/deletion.component';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  @Input() equipment: Equipment;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  public editing: boolean;

  constructor(
    public state: StateService,
    public cache: CacheService,
    public api: ApiService,
    public dialog: MatDialog
  ) {
    this.editing = false;
  }

  ngOnInit(): void {
  }

  public processToggleEditing(): void {
    if(this.editing) {
      this.showAttributes();
    }
  }

  public showAttributes(): void {
    this.tabGroup.selectedIndex = 1;
  }

  public save(): void {
    this.api.updateEquipment(this.equipment);
    this.editing = false;
  }

  private processDelete(result: boolean): void {
    if(result) {
      this.state.clearDetailItem();
      this.api.deleteEquipment(this.equipment);
    }
  }

  public delete(): void {
    let dialogRef: MatDialogRef<DeletionComponent> = this.dialog.open(DeletionComponent);
    dialogRef.afterClosed().subscribe(this.processDelete.bind(this));
  }

}
