<mat-expansion-panel hideToggle (click)="this.activateDetails()">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <img [src]="this.equipment.getImageUrl()" />
            {{ this.equipment.name }}
        </mat-panel-title>
        <mat-panel-description [ngClass]="this.getIssueClass()">
            <span mat-line>{{ this.getIssueInfo() }}</span>
            <mat-icon>{{ this.getIcon() }}</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>
</mat-expansion-panel>