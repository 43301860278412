import { Component, Input, OnInit } from '@angular/core';
import { InspectionPlan } from 'src/app/models/inspection-plan';
import { Summarizable } from '../../summarizable';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  @Input() item: Summarizable;

  constructor() { }

  ngOnInit(): void {
  }

  public getIntervalString(): string {
    if(this.item.getType() == 'inspection-plan') {
      return (<InspectionPlan>this.item).interval.name + ' interval';
    }
    return null;
  }

}
