import { Baseline } from './baseline';
import { AnalysisProfile } from './analysis-profile';
import { InspectionReading } from './inspection-reading';
import { environment } from 'src/environments/environment';

export class BaselineReading {
    id: number;
    active: boolean;
    baseline: Baseline;
    emissivity: number;
    gridColumn: number;
    gridRow: number;
    imageLink: string;
    notes: string;
    profile: AnalysisProfile;

    inspectionReadings: InspectionReading[];

    constructor(id: number, active: boolean, baseline: Baseline, emissivity: number, gridColumn: number, gridRow: number, imageLink: string, notes: string, profile: AnalysisProfile) {
        this.id = id;
        this.active = active;
        this.baseline = baseline;
        this.emissivity = emissivity;
        this.gridColumn = gridColumn;
        this.gridRow = gridRow;
        this.imageLink = imageLink;
        this.notes = notes;
        this.profile = profile;

        this.inspectionReadings = [];
    }

    public addInspectionReading(reading: InspectionReading): void {
        this.inspectionReadings.push(reading);
    }

    public getImageUrl(): string {
        if(this.imageLink) {
            return environment.imageUrl + this.imageLink;
        }
        return '';
    }
}
