import { Equipment } from './equipment';
import { InspectionPlanExecution } from './inspection-plan-execution';
import { InspectionStatus } from './inspection-status';
import { InspectionReading } from './inspection-reading';
import { InspectionAlarm } from './inspection-alarm';
import { BaselineReading } from './baseline-reading';
import { Baseline } from './baseline';

export class Inspection {
    id: number;
    equipment: Equipment;
    execution: InspectionPlanExecution;
    notes: string;
    status: InspectionStatus;

    readings: InspectionReading[];

    constructor(id: number, equipment: Equipment, execution: InspectionPlanExecution, notes: string, status: InspectionStatus) {
        this.id = id;
        this.equipment = equipment;
        this.execution = execution;
        this.notes = notes;
        this.status = status;

        this.readings = [];
    }

    public addReading(reading: InspectionReading): void {
        this.readings.push(reading);
    }

    public getAlerts(): number[] {
        let alerts: number[] = [0, 0, 0, 0];
        for(let i: number = 0; i < this.readings.length; i++) {
            const reading: InspectionReading = this.readings[i];
            const newAlerts: number[] = reading.getAlerts();
            for(let j: number = 0; j < 4; j++) {
                alerts[j] += newAlerts[j];
            }
        }
        return alerts;
    }

    public getAlarms(): InspectionAlarm[] {
        let alarms: InspectionAlarm[] = [];
        for(let i: number = 0; i < this.readings.length; i++) {
            const reading: InspectionReading = this.readings[i];
            alarms = alarms.concat(reading.alarms);
        }
        return alarms;
    }

    public getLastReadingForBaselineReading(baselineReading: BaselineReading): InspectionReading {
        let finalReading: InspectionReading = null;
        for(let i: number = 0; i < this.readings.length; i++) {
            const reading: InspectionReading = this.readings[i];
            if(reading.baselineReading == baselineReading) {
                if(!finalReading || reading.recordedAt > finalReading.recordedAt) {
                    finalReading = reading;
                }
            }
        }
        return finalReading;
    }

    public getOrdered(): InspectionReading[] {
        let readings: InspectionReading[] = [];
        const baselineReadings: BaselineReading[] = this.equipment.getActiveBaseline().getOrdered();
        for(let i: number = 0; i < baselineReadings.length; i++) {
            const baselineReading: BaselineReading = baselineReadings[i];
            readings.push(this.getLastReadingForBaselineReading(baselineReading));
        }
        return readings;
    }
    
    public getValidated(): boolean {
        if(!this.equipment.getActiveBaseline()) {
            return false;
        }
        const baselineReadings: BaselineReading[] = this.equipment.getActiveBaseline().readings;
        for(let i: number = 0; i < baselineReadings.length; i++) {
            const baselineReading: BaselineReading = baselineReadings[i];
            const reading: InspectionReading = this.getLastReadingForBaselineReading(baselineReading);
            if(!reading || !reading.getValidated()) {
                return false;
            }
        }
        return true;
    }

    public getBaseline(): Baseline {
        return this.equipment.getActiveBaseline();
    }
}
