import { Component, Input, OnInit } from '@angular/core';
import { Baseline } from 'src/app/models/baseline';
import { Equipment } from 'src/app/models/equipment';
import { Summarizable } from '../../summarizable';

@Component({
  selector: 'app-detail-panel',
  templateUrl: './detail-panel.component.html',
  styleUrls: ['./detail-panel.component.scss']
})
export class DetailPanelComponent implements OnInit {

  @Input() item: Summarizable;
  @Input() compact?: boolean;

  constructor() { }

  ngOnInit(): void {
    if(!this.compact) {
      this.compact = false;
    }
  }

}
