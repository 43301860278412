<section class="container">
    <header>
        <div class="baseline">
            <div *ngFor="let row of this.inspection.getBaseline().getShaped()" class="baseline-row">
                <div *ngFor="let reading of row" [ngClass]="this.getReadingClass(reading)" (click)="this.handleClick(reading)">
                    <mat-icon>{{ this.getReadingIcon(reading) }}</mat-icon>
                </div>
            </div>
        </div>
    </header>

    <div [style.display]="selected ? 'block' : 'none'">
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
    </div>
</section>