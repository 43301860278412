<mat-toolbar>
    <button mat-button (click)="this.activateInspectionPlans()">
        <mat-icon>playlist_add_check</mat-icon>
        <span> Inspection plans</span>
    </button>
    <button mat-button (click)="this.activateFacilities()">
        <mat-icon>domain</mat-icon>
        <span> Facilities</span>
    </button>
    <span class="spacer"></span>
    <mat-form-field>
        <mat-select [(value)]="this.getCurrentUser().lastActiveOrganization">
            <mat-option *ngFor="let organization of this.getOrganizations()" [value]="organization">{{ organization.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-button (click)="this.logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span> Log out</span>
    </button>
</mat-toolbar>
<mat-card>
    <mat-card-header>
        <mat-card-title>Next inspections</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-checkbox color="primary" [(ngModel)]="this.nextFiltered">My plans</mat-checkbox><br />
        <app-summary [items]="this.getNextInspectionPlans()" [filtered]="this.nextFiltered" [limit]="4"></app-summary>
    </mat-card-content>
    <mat-card-header>
        <mat-card-title>Top outstanding issues</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-checkbox color="primary" [(ngModel)]="this.topFiltered">My plans</mat-checkbox><br />
        <app-summary [items]="this.getOutstandingInspectionPlans()" [filtered]="this.topFiltered" [limit]="4"></app-summary>
    </mat-card-content>
</mat-card>