<app-details [item]="this.item"></app-details>
<mat-tab-group *ngIf="['equipment', 'facility', 'inspection-plan'].includes(this.item.getType())">
    <mat-tab [label]="this.item.getOverviewTitle()">
        <app-equipment *ngIf="this.item.getType() == 'equipment'" [equipment]="this.item"></app-equipment>
        <app-facility *ngIf="this.item.getType() == 'facility'" [facility]="this.item"></app-facility>
        <app-inspection-plan *ngIf="this.item.getType() == 'inspection-plan'" [plan]="this.item"></app-inspection-plan>
    </mat-tab>
    <mat-tab label="Info">
        <mat-list>
            <ng-container *ngFor="let detail of item.getDetails()">
                <h3 matSubheader>{{ detail.title }}</h3>
                <mat-list-item *ngFor="let bodyItem of detail.body">
                    <h3 matLine>{{ bodyItem }}</h3>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </mat-tab>
    <mat-tab label="Issue history">
        Available soon with a <span style="color: gold"><mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon>PREMIUM<mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon></span> account!
    </mat-tab>
    <mat-tab label="Thermal history">
        Available soon with a <span style="color: gold"><mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon>PREMIUM<mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon><mat-icon>attach_money</mat-icon></span> account!
    </mat-tab>
</mat-tab-group>