import { Component, OnInit } from '@angular/core';
import { Organization } from '../../models/organization';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss']
})
export class OrganizationSelectComponent implements OnInit {

  constructor(
    private state: StateService
  ) { }

  public getOrganizations(): Organization[] {
    if(this.state.getCurrentUser()) {
      return this.state.getCurrentUser().organizations;
    }
    return [];
  }

  public selectOrganization(organization: Organization): void {
    if(this.state.getCurrentUser()) {
      this.state.getCurrentUser().lastActiveOrganization = organization;
    }
  }

  ngOnInit(): void {
  }

}
