import { BaselineReading } from './baseline-reading';
import { Inspection } from './inspection';
import { ReadingStatus } from './reading-status';
import { InspectionAlarm } from './inspection-alarm';
import { User } from './user';
import { environment } from 'src/environments/environment';
import { InspectionReadingAnomaly } from './inspection-reading-anomaly';

export class InspectionReading {
    id: number;
    ambientTemp: number;
    baselineReading: BaselineReading;
    imageLink: string;
    inspection: Inspection;
    maxTemp: number;
    notes: string;
    recordedAt: Date;
    status: ReadingStatus;
    validationNote: string;
    validationUser: User;
    validationAt: Date;

    alarms: InspectionAlarm[];
    anomalies: InspectionReadingAnomaly[];

    constructor(id: number, ambientTemp: number, baselineReading: BaselineReading, imageLink: string, inspection: Inspection, maxTemp: number, notes: string, recordedAt: Date, status: ReadingStatus, validationUser: User, validationNote: string, validationAt: Date) {
        this.id = id;
        this.ambientTemp = ambientTemp;
        this.baselineReading = baselineReading;
        this.imageLink = imageLink;
        this.inspection = inspection;
        this.maxTemp = maxTemp;
        this.notes = notes;
        this.recordedAt = recordedAt;
        this.status = status;
        this.validationUser = validationUser;
        this.validationNote = validationNote;
        this.validationAt = validationAt;

        this.alarms = [];
        this.anomalies = [];
    }

    public addAlarm(alarm: InspectionAlarm): void {
        this.alarms.push(alarm);
    }

    public addAnomaly(anomaly: InspectionReadingAnomaly): void {
        this.anomalies.push(anomaly);
    }

    public getImageUrl(): string {
        if(this.imageLink) {
            return environment.imageUrl + this.imageLink;
        }
        return '';
    }

    public getThermalUrl(): string {
        if(this.imageLink) {
            return environment.imageUrl + 'thermal/' + this.imageLink;
        }
        return '';
    }

    public getThermalScaledUrl(cutoff?: number): string {
        if(this.imageLink) {
            let url: string = environment.imageUrl + 'thermalScaled/' + this.imageLink;
            if(cutoff) {
                url += '/' + cutoff.toString();
            }
            return url;
        }
        return '';
    }

    public getAlerts(): number[] {
        if(this.anomalies.length == 0) {
            return [1,0,0,0];
        }
        let alerts: number[] = [0,0,0,0];
        for(let i: number = 0; i < this.anomalies.length; i++) {
            const anomaly: InspectionReadingAnomaly = this.anomalies[i];
            alerts[anomaly.severity - 1]++;
        }
        return alerts;
    }

    public getAlertLevel(): number {
        const alerts: number[] = this.getAlerts();
        for(let i: number = 3; i >= 0; i--) {
            if(alerts[i] > 0) {
                return i + 1;
            }
        }
        return 1;
    }

    public getValidated(): boolean {
        if(this.status.id == 5) {
            return false;
        }
        if(this.validationAt) {
            return true;
        }
    }

    public getAnomaliesSorted(): InspectionReadingAnomaly[] {
        return this.anomalies.sort(function(a, b: InspectionReadingAnomaly) {
            if(a.severity > b.severity) {
                return 1;
            }
            if(a.severity < b.severity) {
                return -1;
            }
            return 0;
        }).reverse();
    }
}
