import { Organization } from './organization';

export class AnalysisProfile {
    id: number;
    name: string;
    organization: Organization;
    description: string;
    isDefault: boolean;
    rangeMin: number;
    rangeMax: number;
    thresholds: number[];

    constructor(id: number, name: string, organization: Organization, description: string, isDefault: boolean, rangeMin: number, rangeMax: number, thresholds: number[]) {
        this.id = id;
        this.name = name;
        this.organization = organization;
        this.description = description;
        this.isDefault = isDefault;
        this.rangeMin = rangeMin;
        this.rangeMax = rangeMax;
        this.thresholds = thresholds;
    }
}
