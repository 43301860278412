import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  @Input() alerts: number[];

  constructor() { }

  ngOnInit(): void {
  }

  public getAlerts(): number[] {
    return [this.alerts[3], this.alerts[2], this.alerts[1]];
  }

}
