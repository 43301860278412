import { Injectable } from '@angular/core';
import { isUndefined } from 'util';
import { AnalysisProfile } from '../models/analysis-profile';
import { Baseline } from '../models/baseline';
import { BaselineReading } from '../models/baseline-reading';
import { Equipment } from '../models/equipment';
import { EquipmentType } from '../models/equipment-type';
import { Facility } from '../models/facility';
import { Inspection } from '../models/inspection';
import { InspectionAlarm } from '../models/inspection-alarm';
import { InspectionInterval } from '../models/inspection-interval';
import { InspectionPlan } from '../models/inspection-plan';
import { InspectionPlanExecution } from '../models/inspection-plan-execution';
import { InspectionReading } from '../models/inspection-reading';
import { InspectionReadingAnomaly } from '../models/inspection-reading-anomaly';
import { InspectionStatus } from '../models/inspection-status';
import { Organization } from '../models/organization';
import { ReadingStatus } from '../models/reading-status';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private equipmentTypes: EquipmentType[];
  private readingStatuses: ReadingStatus[];
  private inspectionStatuses: InspectionStatus[];
  private inspectionIntervals: InspectionInterval[];
  private organizations: Organization[];
  private analysisProfiles: AnalysisProfile[];
  private users: User[];
  private facilities: Facility[];
  private inspectionPlans: InspectionPlan[];
  private equipment: Equipment[];
  private baselines: Baseline[];
  private baselineReadings: BaselineReading[];
  private inspectionPlanExecutions: InspectionPlanExecution[];
  private inspections: Inspection[];
  private inspectionReadings: InspectionReading[];
  private inspectionReadingAnomalies: InspectionReadingAnomaly[];
  private inspectionAlarms: InspectionAlarm[];

  constructor() {
    this.equipmentTypes = [];
    this.readingStatuses = [];
    this.inspectionStatuses = [];
    this.inspectionIntervals = [];
    this.organizations = [];
    this.analysisProfiles = [];
    this.users = [];
    this.facilities = [];
    this.inspectionPlans = [];
    this.equipment = [];
    this.baselines = [];
    this.inspectionPlanExecutions = [];
    this.baselineReadings = [];
    this.inspections = [];
    this.inspectionReadings = [];
    this.inspectionReadingAnomalies = [];
    this.inspectionAlarms = [];
  }

  public updateEquipmentType(type: EquipmentType): void {
    this.equipmentTypes[type.id] = type;
  }

  public hasEquipmentType(id: number): boolean {
    if(this.equipmentTypes[id]) {
      return true;
    }
    return false;
  }

  public getEquipmentType(id: number): EquipmentType {
    return this.equipmentTypes[id];
  }

  public getEquipmentTypes(): EquipmentType[] {
    return this.equipmentTypes.filter(function(el: EquipmentType) {
      return el != undefined && el != null;
    });
  }

  public updateReadingStatus(status: ReadingStatus): void {
    this.readingStatuses[status.id] = status;
  }

  public hasReadingStatus(id: number): boolean {
    if(this.readingStatuses[id]) {
      return true;
    }
    return false;
  }

  public getReadingStatus(id: number): ReadingStatus {
    return this.readingStatuses[id];
  }

  public updateInspectionStatus(status: InspectionStatus): void {
    this.inspectionStatuses[status.id] = status;
  }

  public hasInspectionStatus(id: number): boolean {
    if(this.inspectionStatuses[id]) {
      return true;
    }
    return false;
  }

  public getInspectionStatus(id: number): InspectionStatus {
    return this.inspectionStatuses[id];
  }

  public updateInspectionInterval(interval: InspectionInterval): void {
    this.inspectionIntervals[interval.id] = interval;
  }

  public hasInspectionInterval(id: number): boolean {
    if(this.inspectionIntervals[id]) {
      return true;
    }
    return false;
  }

  public getInspectionInterval(id: number): InspectionInterval {
    return this.inspectionIntervals[id];
  }

  public getInspectionIntervals(): InspectionInterval[] {
    return this.inspectionIntervals.filter(function(el: InspectionInterval) {
      return el != undefined && el != null;
    });
  }

  public updateOrganization(organization: Organization): void {
    this.organizations[organization.id] = organization;
  }

  public hasOrganization(id: number) {
    if(this.organizations[id]) {
      return true;
    }
    return false;
  }

  public getOrganization(id: number): Organization {
    return this.organizations[id];
  }

  public getFirstOrganization(): Organization {
    if(this.organizations.length > 0) {
      for(let i: number = 0; i < this.organizations.length; i++) {
        const organization: Organization = this.organizations[i];
        if(organization) return organization;
      }
    }
    return null;
  }

  public updateAnalysisProfile(profile: AnalysisProfile): void {
    this.analysisProfiles[profile.id] = profile;
  }

  public hasAnalysisProfile(id: number): boolean {
    if(this.analysisProfiles[id]) {
      return true;
    }
    return false;
  }

  public getAnalysisProfile(id: number): AnalysisProfile {
    return this.analysisProfiles[id];
  }

  public updateUser(user: User): void {
    this.users[user.id] = user;
  }

  public hasUser(id: number) {
    if(this.users[id]) {
      return true;
    }
    return false;
  }

  public getUser(id: number): User {
    return this.users[id];
  }

  public getUserByKey(key: string): User {
    for(let i: number = 0; i < this.users.length; i++) {
      let user: User = this.users[i];
      if(user && user.authKey == key) {
        return user;
      }
    }
    return null;
  }

  public updateFacility(facility: Facility): void {
    this.facilities[facility.id] = facility;
  }

  public removeFacility(facility: Facility): void {
      const index: number = this.facilities.indexOf(facility);
      if(index > -1) {
          this.facilities.splice(index, 1);
      }
  }

  public hasFacility(id: number): boolean {
    if(this.facilities[id]) {
      return true;
    }
    return false;
  }

  public getFacility(id: number): Facility {
    return this.facilities[id];
  }

  public updateInspectionPlan(plan: InspectionPlan): void {
    this.inspectionPlans[plan.id] = plan;
  }

  public removeInspectionPlan(plan: InspectionPlan): void {
      const index: number = this.inspectionPlans.indexOf(plan);
      if(index > -1) {
          this.inspectionPlans.splice(index, 1);
      }
  }

  public hasInspectionPlan(id: number): boolean {
    if(this.inspectionPlans[id]) {
      return true;
    }
    return false;
  }

  public getInspectionPlan(id: number): InspectionPlan {
    return this.inspectionPlans[id];
  }

  public updateEquipment(equipment: Equipment): void {
    this.equipment[equipment.id] = equipment;
  }

  public removeEquipment(equipment: Equipment): void {
    const index: number = this.equipment.indexOf(equipment);
    if(index) {
      this.equipment.splice(index, 1);
    }
  }

  public hasEquipment(id: number): boolean {
    if(this.equipment[id]) {
      return true;
    }
    return false;
  }

  public getEquipment(id: number): Equipment {
    return this.equipment[id];
  }

  public updateBaseline(baseline: Baseline): void {
    this.baselines[baseline.id] = baseline;
  }

  public hasBaseline(id: number): boolean {
    if(this.baselines[id]) {
      return true;
    }
    return false;
  }

  public getBaseline(id: number): Baseline {
    return this.baselines[id];
  }

  public updateInspectionPlanExecution(execution: InspectionPlanExecution): void {
    this.inspectionPlanExecutions[execution.id] = execution;
  }

  public hasInspectionPlanExecution(id: number): boolean {
    if(this.inspectionPlanExecutions[id]) {
      return true;
    }
    return false;
  }

  public getInspectionPlanExecution(id: number): InspectionPlanExecution {
    return this.inspectionPlanExecutions[id];
  }

  public updateBaselineReading(reading: BaselineReading): void {
    this.baselineReadings[reading.id] = reading;
  }

  public hasBaselineReading(id: number): boolean {
    if(this.baselineReadings[id]) {
      return true;
    }
    return false;
  }

  public getBaselineReading(id: number): BaselineReading {
    return this.baselineReadings[id];
  }

  public updateInspection(inspection: Inspection): void {
    this.inspections[inspection.id] = inspection;
  }

  public hasInspection(id: number): boolean {
    if(this.inspections[id]) {
      return true;
    }
    return false;
  }

  public getInspection(id: number): Inspection {
    return this.inspections[id];
  }

  public updateInspectionReading(reading: InspectionReading): void {
    this.inspectionReadings[reading.id] = reading;
  }

  public hasInspectionReading(id: number): boolean {
    if(this.inspectionReadings[id]) {
      return true;
    }
    return false;
  }

  public getInspectionReading(id: number): InspectionReading {
    return this.inspectionReadings[id];
  }

  public updateInspectionReadingAnomaly(anomaly: InspectionReadingAnomaly): void {
    this.inspectionReadingAnomalies[anomaly.id] = anomaly;
  }

  public hasInspectionReadingAnomaly(id: number): boolean {
    if(this.inspectionReadingAnomalies[id]) {
      return true;
    }
    return false;
  }

  public getInspectionReadingAnomaly(id: number): InspectionReadingAnomaly {
    return this.inspectionReadingAnomalies[id];
  }

  public updateInspectionAlarm(alarm: InspectionAlarm): void {
    this.inspectionAlarms[alarm.id] = alarm;
  }

  public hasInspectionAlarm(id: number): boolean {
    if(this.inspectionAlarms[id]) {
      return true;
    }
    return false;
  }

  public getInspectionAlarm(id: number): InspectionAlarm {
    return this.inspectionAlarms[id];
  }
}
