import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Section } from '../section';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

  @Input() section: Section;
  @Input() level: number;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  private editingTitle: boolean;
  private editingBody: boolean;

  constructor() {
    this.editingTitle = false;
    this.editingBody = false;
  }

  ngOnInit(): void {
  }

  public addSection(): void {
    const section: Section = new Section({
      title: 'Enter title',
      body: 'Enter body',
      numbered: this.level < 4 ? true : false,
      sections: []
    });
    this.section.sections.push(section);
  }

  public getEditingTitle(): boolean {
    return this.editingTitle;
  }

  public getEditingBody(): boolean {
    return this.editingBody;
  }

  public toggleEditingBody(): void {
    this.editingBody = !this.editingBody;
  }

  public toggleEditingTitle(): void {
    this.editingTitle = !this.editingTitle;
  }

}
