<mat-card>
    <mat-card-header *ngIf="this.activeReading">
        <mat-card-title>{{ this.activeReading.gridRow + 1 }} - {{ this.activeReading.gridColumn + 1 }}</mat-card-title>
        <mat-card-subtitle>
            Emissivity: {{ this.activeReading.emissivity }}%
            <span *ngIf="this.activeReading.profile">
                <span *ngIf="this.activeReading.profile.name">
                    - Analysis profile: {{ this.activeReading.profile.name }}
                </span>
                 - Range: {{ this.activeReading.profile.rangeMin }} °C - {{ this.activeReading.profile.rangeMax }} °C
                 - Thresholds:
                <span *ngFor="let threshold of this.activeReading.profile.thresholds"> {{ threshold }} °C |</span>
            </span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content [ngStyle]="{'max-width': (this.baseline.gridColumns*15).toString()+'em'}">
        <mat-grid-list [cols]="this.baseline.gridColumns" rowHeight="3:4">
            <mat-grid-tile
                *ngFor="let reading of this.baseline.getSerial()"
                (click)="this.activateReading(reading)"
            >
                <img *ngIf="reading" [src]="reading.getImageUrl()" />
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
</mat-card>
