<ng-container *ngIf="this.getCurrentUser() && this.getOrganization(); else noUser">
  <app-logo></app-logo>
  <app-main-menu *ngIf="!this.state.getDetailItem() && !this.state.getActivePanel(); else noMenu"></app-main-menu>
  <ng-template #noMenu>
    <app-top-label [label]="this.state.getLabel()" [type]="this.state.getActivePanel()"></app-top-label>
    <mat-drawer-container>
      <mat-drawer *ngIf="this.state.getActiveForm()" opened mode="over" position="end">
        <app-inspection-plan-form *ngIf="this.state.getActiveForm() == 'inspection-plan'"></app-inspection-plan-form>
        <app-facility-form *ngIf="this.state.getActiveForm() == 'facility'"></app-facility-form>
        <app-equipment-form *ngIf="this.state.getActiveForm() == 'equipment'" [facility]="this.state.getDetailItem()"></app-equipment-form>
        <app-report-form *ngIf="this.state.getActiveForm() == 'report'" [plan]="this.state.getDetailItem()"></app-report-form>
      </mat-drawer>
      <mat-drawer-content>
        <ng-container *ngIf="this.state.getDetailItem(); else noDetailItem">
          <app-inspection-plan *ngIf="this.state.getDetailItem().getType() == 'inspection-plan'" [plan]="this.state.getDetailItem()"></app-inspection-plan>
          <app-equipment *ngIf="this.state.getDetailItem().getType() == 'equipment'" [equipment]="this.state.getDetailItem()"></app-equipment>
          <app-facility *ngIf="this.state.getDetailItem().getType() == 'facility'" [facility]="this.state.getDetailItem()"></app-facility>
        </ng-container>
        <ng-template #noDetailItem>
          <mat-card>
            <mat-checkbox color="primary" *ngIf="this.state.getActivePanel() == 'inspection-plans'" [(ngModel)]="this.filtered">My plans</mat-checkbox><br />
            <app-summary *ngIf="this.state.getActivePanel() == 'inspection-plans'" [items]="this.getInspectionPlans()" [filtered]="this.filtered"></app-summary>
            <app-summary *ngIf="this.state.getActivePanel() == 'facilities'" [items]="this.getFacilities()"></app-summary>
            <app-summary *ngIf="this.state.getActivePanel() == 'alerts'" [items]="this.getAlarms()"></app-summary>
          </mat-card>
        </ng-template>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-template>
</ng-container>
<ng-template #noUser>
  <div>
    <img src="assets/KalorECM.svg" />
  </div>
</ng-template>