<mat-card>
    <mat-card-header>
        <img mat-card-avatar [src]="this.equipment.facility.getImageUrl()" />
        <mat-card-title>{{ this.equipment.name }}</mat-card-title>
        <mat-card-subtitle>
            <span>{{ this.equipment.facility.name }}</span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p *ngIf="this.equipment.getSubLabel()">{{ this.equipment.getSubLabel() }}</p>
    </mat-card-content>
    <mat-card-actions>
        <mat-checkbox color="primary" [(ngModel)]="this.editing" (change)="this.processToggleEditing()" name="editing">Edit</mat-checkbox>
        <button mat-raised-button color="primary" *ngIf="this.editing" (click)="this.save()">Save</button>
        <button mat-button *ngIf="this.editing && this.equipment.canDelete(this.state.getCurrentUser())" (click)="this.delete()">Delete</button>
    </mat-card-actions>
</mat-card>
<mat-tab-group #tabGroup>
    <mat-tab [label]="this.equipment.getOverviewTitle()">
        <mat-accordion>
            <app-inspection *ngFor="let inspection of this.equipment.inspections" [inspection]="inspection"></app-inspection>
        </mat-accordion>
    </mat-tab>
    <mat-tab label="Attributes">
        <mat-list *ngIf="!this.editing; else editingTemplate">
            <h3 matSubheader>Facility</h3>
            <mat-list-item><h3 matLine>{{ this.equipment.facility.name }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Name</h3>
            <mat-list-item><h3 matLine>{{ this.equipment.name }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Description</h3>
            <mat-list-item><h3 matLine>{{ this.equipment.description }}</h3></mat-list-item>
            <mat-divider></mat-divider>
            <h3 matSubheader>Location</h3>
            <mat-list-item><h3 matLine>{{ this.equipment.location }}</h3></mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
        <ng-template #editingTemplate>
            <form>
                <mat-list>
                    <h3 matSubheader>Facility</h3>
                    <mat-list-item>
                        <mat-select [(ngModel)]="this.equipment.facility" name="facility">
                            <mat-option *ngFor="let facility of this.state.getActiveOrganization().facilities" [value]="facility">{{ facility.name }}</mat-option>
                        </mat-select>
                    </mat-list-item>
                    <h3 matSubheader>Name</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [(ngModel)]="this.equipment.name" name="name" [value]="this.equipment.name" />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Description</h3>
                    <mat-list-item>
                        <mat-form-field>
                            <textarea matInput [(ngModel)]="this.equipment.description" name="description">{{ this.equipment.description }}</textarea>
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <h3 matSubheader>Location</h3>
                    <mat-list-item>
                        <mat-form-field matLine>
                            <input matInput [(ngModel)]="this.equipment.location" name="location" [value]="this.equipment.location" />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-list>
                <h3 matSubheader>Image</h3>
                <app-image-upload matInput [(value)]="this.equipment.imageLink"></app-image-upload>
            </form>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="this.equipment.getActiveBaseline()" label="Baseline">
        <app-baseline [baseline]="this.equipment.getActiveBaseline()"></app-baseline>
    </mat-tab>
</mat-tab-group>