import { StringValueNode } from 'graphql';
import { Equipment } from '../../models/equipment';
import { InspectionPlanExecution } from '../../models/inspection-plan-execution';
import { Section } from './section';

export interface EquipmentSelection {
  equipment: Equipment;
  selected: boolean;
}

export class ReportType {
    name: string;
    description: string;
}

export interface ReportParameters {
    execution: InspectionPlanExecution,
    type: ReportType,
    title: string,
    logo: ReportType,
    equipment: EquipmentSelection[],
    introTitle: string,
    intro: string,
    methodology: string,
    conclusion: string
}

export class Report {
    execution: InspectionPlanExecution;
    type: ReportType;
    title: string;
    logo: ReportType;
    equipment: Equipment[];
    header: Section;
    footer: Section;
    sections: Section[];
    introTitle: string;
    intro: string;
    methodology: string;
    conclusion: string;

    constructor(parameters: ReportParameters) {
        this.execution = parameters.execution;
        this.type = parameters.type;
        this.title = parameters.title;
        this.logo = parameters.logo;
        this.introTitle = parameters.introTitle;
        this.intro = parameters.intro;
        this.methodology = parameters.methodology;
        this.conclusion = parameters.conclusion;

        this.equipment = [];
        
        for(let i: number = 0; i < parameters.equipment.length; i++) {
            const selection: EquipmentSelection = parameters.equipment[i];
            if(selection.selected) {
                this.equipment.push(selection.equipment);
            }
        }

        this.header = null;
        this.footer = null;

        this.sections = [];
    }
}
