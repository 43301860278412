import { Component, OnInit, Input } from '@angular/core';
import { Facility } from '../../models/facility';

@Component({
  selector: 'app-facility-summary',
  templateUrl: './facility-summary.component.html',
  styleUrls: ['./facility-summary.component.scss']
})
export class FacilitySummaryComponent implements OnInit {

  @Input() facility: Facility;

  constructor() { }

  ngOnInit(): void {
  }

}
