export interface SectionParameters {
    title: string;
    body: string;
    numbered: boolean;
    sections: Section[];
}

export class Section {
    title: string;
    body: string;
    numbered: boolean;
    sections: Section[];

    constructor(parameters: SectionParameters) {
        this.title = parameters.title;
        this.body = parameters.body;
        this.numbered = parameters.numbered;
        this.sections = parameters.sections;
    }
}
