<mat-toolbar>
    <button mat-button (click)="this.backToMenu()"><mat-icon>arrow_back</mat-icon></button>
    <span>{{ this.label }}</span>
    <span class="spacer"></span>
    <button *ngIf="this.hasRefresh()" mat-mini-fab aria-label="REFRESH" (click)="this.refresh()">
        <mat-icon>refresh</mat-icon>
    </button>
    <button *ngIf="this.hasAdd()" mat-mini-fab aria-label="ADD_NEW" (click)="this.handleAdd()">
        <mat-icon>add</mat-icon>
    </button>
</mat-toolbar>