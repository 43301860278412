import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';
import { Facility } from '../../models/facility';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-facility-form',
  templateUrl: './facility-form.component.html',
  styleUrls: ['./facility-form.component.scss']
})
export class FacilityFormComponent implements OnInit {

  public facility: Facility;

  constructor(
    public stateService: StateService,
    public apiService: ApiService
  ) {
    this.facility = new Facility(null, '', '', '', this.stateService.getCurrentUser().lastActiveOrganization, '', '', this.stateService.getCurrentUser(), new Date());
  }

  ngOnInit(): void {
  }

  public save(): void {
    this.apiService.addFacility(this.facility);
    this.stateService.clearActiveForm();
  }

}
