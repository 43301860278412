import { Component, OnInit, Input } from '@angular/core';
import { InspectionAlarm } from '../../models/inspection-alarm';

@Component({
  selector: 'app-inspection-alarm-summary',
  templateUrl: './inspection-alarm-summary.component.html',
  styleUrls: ['./inspection-alarm-summary.component.scss']
})
export class InspectionAlarmSummaryComponent implements OnInit {
  
  @Input() alarm: InspectionAlarm;

  constructor() { }

  ngOnInit(): void {
  }

}
