import { Component, Input, OnInit } from '@angular/core';
import { InspectionPlan } from 'src/app/models/inspection-plan';
import { InspectionPlanExecution } from 'src/app/models/inspection-plan-execution';
import { Equipment } from '../../models/equipment';
import { Summarizable } from '../../summarizable';

@Component({
  selector: 'app-overview-panel',
  templateUrl: './overview-panel.component.html',
  styleUrls: ['./overview-panel.component.scss']
})
export class OverviewPanelComponent implements OnInit {

  @Input() item: Summarizable;

  constructor() { }

  ngOnInit(): void {
  }

  public getActiveBaseline() {
    return (<Equipment>this.item).getActiveBaseline();
  }

  public getLastExecution(): InspectionPlanExecution {
    if(this.item.getType() == 'inspection-plan') {
      return (<InspectionPlan>this.item).getLastExecution();
    }

    return null;
  }

}
