import { Component, Input, OnInit } from '@angular/core';
import { InspectionReading } from 'src/app/models/inspection-reading';
import { InspectionReadingAnomaly } from 'src/app/models/inspection-reading-anomaly';
import { ApiService } from 'src/app/services/api.service';
import { StateService } from 'src/app/services/state.service';

interface SeverityDefinition {
  severity: number;
  color: string;
  name: string;
}

@Component({
  selector: 'app-inspection-reading',
  templateUrl: './inspection-reading.component.html',
  styleUrls: ['./inspection-reading.component.scss']
})
export class InspectionReadingComponent implements OnInit {

  @Input() reading: InspectionReading;

  private severities: SeverityDefinition[];

  private highlightedAnomaly: InspectionReadingAnomaly;

  private thermalMode: boolean;
  private zoomMode: boolean;

  constructor(
    private stateService: StateService,
    private apiService: ApiService
  ) {
    this.highlightedAnomaly = null;
    this.thermalMode = false;
    this.zoomMode = false;

    this.severities = [
      {severity: 1, color: 'limegreen', name: 'No alert'},
      {severity: 2, color: 'gold', name: 'Low-risk alert'},
      {severity: 3, color: 'coral', name: 'High-risk alert'},
      {severity: 4, color: 'crimson', name: 'Critical alert'}
    ];
  }

  ngOnInit(): void {
  }

  public validateReading(reading: InspectionReading): void {
    reading.validationAt = new Date();
    reading.validationUser = this.stateService.getCurrentUser();
    this.apiService.validateInspectionReading(reading);
  }

  public getSeverities(): SeverityDefinition[] {
    return this.severities;
  }

  public getSeverity(severity: number): SeverityDefinition {
    return this.severities[severity - 1];
  }

  private getSeverityColor(severity: number): string {
    if(severity) {
      return this.getSeverity(severity).color;
    }
    return 'lightslategray';
  }

  private getBorderWidth(anomaly: InspectionReadingAnomaly): number {
    if(anomaly == this.highlightedAnomaly) {
      return 4;
    }
    return 1;
  }

  public anomalyFrameStyle(anomaly: InspectionReadingAnomaly): any {
    return {
      'top': (anomaly.y * 100).toString() + '%',
      'left': (anomaly.x * 100).toString() + '%',
      'width': (anomaly.width * 100).toString() + '%',
      'height': (anomaly.height * 100).toString() + '%',
      'border-color': this.getSeverityColor(anomaly.severity),
      'border-width': this.getBorderWidth(anomaly).toString() + 'px'
    }
  }

  public highlightAnomaly(anomaly: InspectionReadingAnomaly): void {
    this.highlightedAnomaly = anomaly;
  }

  public unhighlightAnomaly(anomaly: InspectionReadingAnomaly): void {
    if(this.highlightedAnomaly == anomaly) {
      this.highlightedAnomaly = null;
    }
  }

  public getImageUrl(): string {
    if(this.thermalMode) {
      return this.reading.getThermalScaledUrl();
    }
    return this.reading.getImageUrl();
  }

  public getThermalMode(): boolean {
    return this.thermalMode;
  }

  public setThermalMode(mode: boolean): void {
    this.thermalMode = mode;
  }

  public getZoomMode(): boolean {
    return this.zoomMode;
  }

  public setZoomMode(mode: boolean): void {
    this.zoomMode = mode;
  }
}
