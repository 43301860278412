import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Equipment } from 'src/app/models/equipment';
import { Inspection } from 'src/app/models/inspection';
import { InspectionReadingAnomaly } from 'src/app/models/inspection-reading-anomaly';
import { Report } from '../report';
import { BorderStyle, Document, HeadingLevel, HyperlinkRef, Media, Packer, Paragraph, PictureRun, Table, TableCell, TableOfContents, TableRow, WidthType } from 'docx';
import { saveAs } from 'file-saver';
import { StateService } from 'src/app/services/state.service';
import { InspectionReading } from 'src/app/models/inspection-reading';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { decode } from 'jpeg-js';
import { encode, PNGDataArray } from 'fast-png';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedbackService } from 'src/app/services/feedback.service';

interface ReadingImagePreload {
  reading: InspectionReading,
  visual: ArrayBuffer,
  thermal: ArrayBuffer
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnChanges {

  @Input() report: Report;
  @Input() equipment: Equipment[];

  @ViewChild('reportElement') reportElement: ElementRef;

  private doc: Document;

  private preloads: ReadingImagePreload[];
  private oldLength: number;

  constructor(
    private state: StateService,
    private http: HttpClient,
    private feedback: FeedbackService
  ) {
    this.preloads = [];
  }

  ngOnInit(): void {
    this.doc = new Document({
      creator: this.state.getCurrentUser().name,
      description: 'Skýrsla úr Kalor Metrics',
      title: this.report.title,
      subject: this.report.type.name,
      keywords: 'Hitaeftirlit',
      styles: {
        paragraphStyles: [
          {
            id: 'Normal',
            name: 'Normal',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              font: 'Roboto'
            },
            paragraph: {
              indent: {
                left: this.cmToTwip(1)
              },
              spacing: {
                before: this.cmToTwip(0.2),
                after: this.cmToTwip(0.3)
              }
            }
          },
          {
            id: 'Title',
            name: 'Title',
            basedOn: 'Title',
            next: 'Title',
            quickFormat: true,
            run: {
              size: 40,
              color: '000000'
            }
          },
          {
            id: 'Heading 1',
            name: 'Heading 1',
            basedOn: 'Heading 1',
            next: 'Heading 1',
            quickFormat: true,
            run: {
              size: 34,
              color: '000000'
            }
          },
          {
            id: 'Heading 2',
            name: 'Heading 2',
            basedOn: 'Heading 1',
            next: 'Heading 1',
            quickFormat: true,
            run: {
              size: 30
            }
          },
          {
            id: 'Heading 3',
            name: 'Heading 3',
            basedOn: 'Heading 2',
            next: 'Heading 2',
            quickFormat: true,
            run: {
              size: 28
            }
          },
          {
            id: 'Heading 4',
            name: 'Heading 4',
            basedOn: 'Heading 3',
            next: 'Heading 3',
            quickFormat: true,
            run: {
              size: 26
            }
          },
          {
            id: 'Heading 5',
            name: 'Heading 5',
            basedOn: 'Heading 4',
            next: 'Heading 4',
            quickFormat: true,
            run: {
              size: 24
            }
          },
          {
            id: 'Heading 6',
            name: 'Heading 6',
            basedOn: 'Heading 5',
            next: 'Heading 5',
            quickFormat: true,
            run: {
              size: 22
            }
          }
        ]
      }
    });
    if(this.report && this.equipment && this.equipment.length != this.oldLength) {
      this.preloadImages();
    }
  }

  ngOnChanges(): void {
    if(this.report && this.equipment && this.equipment.length != this.oldLength) {
      this.oldLength = this.equipment.length;
      this.preloadImages();
    }
  }

  private getPreloadForReading(reading: InspectionReading): ReadingImagePreload {
    for(let i: number = 0; i < this.preloads.length; i++) {
      const preload: ReadingImagePreload = this.preloads[i];
      if(preload.reading == reading) {
        return preload;
      }
    }
    return null;
  }

  private preloadThermal(preload: ReadingImagePreload): void {
    this.http
      .get(preload.reading.getThermalScaledUrl(), {
        responseType: 'arraybuffer',
        withCredentials: false
      })
      .subscribe({
        next: (response: ArrayBuffer) => {
          preload.thermal = response;
          this.checkPreload();
        }
      });
  }

  private markAnomaly(anomaly: InspectionReadingAnomaly, image: ArrayBuffer, width: number, height: number): ArrayBuffer {
    const lineRatio: number = 0.05;
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const imageBuffer = decode(image);
    const iData = new ImageData(new Uint8ClampedArray(imageBuffer.data), width, height);
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    context.putImageData(iData, 0, 0);
    context.lineWidth = Math.round(width * lineRatio);
    context.strokeStyle = anomaly.getSeverityColor()
    context.strokeRect(
      Math.round(anomaly.x * width),
      Math.round(anomaly.y * height),
      Math.round(anomaly.width * width),
      Math.round(anomaly.height * height)
    );
    const out = encode({
      width: width,
      height: height,
      data: Array.prototype.slice.call(context.getImageData(0, 0, width, height).data)
    });
    return <ArrayBuffer>out.buffer;
  }

  private markAnomalies(reading: InspectionReading, image: ArrayBuffer, width: number, height: number): ArrayBuffer {
    const lineRatio: number = 0.01;
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const imageBuffer = decode(image);
    const iData = new ImageData(new Uint8ClampedArray(imageBuffer.data), width, height);
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    context.putImageData(iData, 0, 0);
    context.lineWidth = Math.round(width * lineRatio);
    for(let i: number = 0; i < reading.anomalies.length; i++) {
      const anomaly: InspectionReadingAnomaly = reading.anomalies[i];
      context.strokeStyle = anomaly.getSeverityColor()
      context.strokeRect(
        Math.round(anomaly.x * width),
        Math.round(anomaly.y * height),
        Math.round(anomaly.width * width),
        Math.round(anomaly.height * height)
      );
    }
    const out = encode({
      width: width,
      height: height,
      data: Array.prototype.slice.call(context.getImageData(0, 0, width, height).data)
    });
    return <ArrayBuffer>out.buffer;
  }

  private getReadingImage(reading): ArrayBuffer {
    const visual: ArrayBuffer = this.getPreloadForReading(reading).visual;
    const width: number = 1080;
    const height: number = 1440;
    return this.markAnomalies(reading, visual, width, height);
  }

  private getReadingThermalImage(reading): ArrayBuffer {
    const visual: ArrayBuffer = this.getPreloadForReading(reading).thermal;
    const width: number = 480;
    const height: number = 640;
    return this.markAnomalies(reading, visual, width, height);
  }

  private getAnomalyImage(anomaly: InspectionReadingAnomaly): ArrayBuffer {
    const visual: ArrayBuffer = this.getPreloadForReading(anomaly.reading).visual;
    const width: number = 1080;
    const height: number = 1440;
    return this.markAnomaly(anomaly, visual, width, height);
  }

  private getAnomalyThermalImage(anomaly: InspectionReadingAnomaly): ArrayBuffer {
    const thermal: ArrayBuffer = this.getPreloadForReading(anomaly.reading).thermal;
    const width: number = 480;
    const height: number = 640;
    return this.markAnomaly(anomaly, thermal, width, height);
  }

  private preloadVisual(preload: ReadingImagePreload): void {
    this.http
      .get(preload.reading.getImageUrl(), {
        responseType: 'arraybuffer',
        withCredentials: false
      })
      .subscribe({
        next: (response: ArrayBuffer) => {
          preload.visual = response;
          this.checkPreload();
        }
      });
  }

  private checkPreload(): void {
    for(let i: number = 0; i < this.preloads.length; i++) {
      const preload: ReadingImagePreload = this.preloads[i];
      if(!preload.visual) {
        this.preloadVisual(preload);
        return;
      }
    }
    for(let i: number = 0; i < this.preloads.length; i++) {
      const preload: ReadingImagePreload = this.preloads[i];
      if(!preload.thermal) {
        this.preloadThermal(preload);
        return;
      }
    }
  }

  private preloadImages(): void {
    const inspections: Inspection[] = this.getInspections();
    this.preloads = [];
    for(let i: number = 0; i < inspections.length; i++) {
      const inspection: Inspection = inspections[i];
      for(let j: number = 0; j < inspection.readings.length; j++) {
        const reading: InspectionReading = inspection.readings[j];
        const preload: ReadingImagePreload = {
          reading: reading,
          visual: null,
          thermal: null
        }
        this.preloads.push(preload);
      }
    }
    this.checkPreload();
  }

  public getInspections(): Inspection[] {
    let inspections: Inspection[] = [];
    for(let i: number = 0; i < this.report.execution.inspections.length; i++) {
      const inspection: Inspection = this.report.execution.inspections[i];
      if(this.equipment.includes(inspection.equipment)) {
        inspections.push(inspection);
      }
    }
    return inspections;
  }

  private getSizeFactor(anomaly: InspectionReadingAnomaly): number {
    return Math.min(8, 1/(1.5 * anomaly.width));
  }

  public getPreviewStyle(anomaly: InspectionReadingAnomaly): any {
    return {
      'background-image': 'url("' + anomaly.reading.getImageUrl() + '")',
      'background-position-x': '-' + ((anomaly.x - anomaly.width/4) * 6 * this.getSizeFactor(anomaly)).toString() + 'em',
      'background-position-y': '-' + ((anomaly.y - anomaly.height/4) * 8 * this.getSizeFactor(anomaly)).toString() + 'em',
      'background-size': (100 * this.getSizeFactor(anomaly)) + '%'
    }
  }

  public getThermalPreviewStyle(anomaly: InspectionReadingAnomaly): any {
    return {
      'background-image': 'url("' + anomaly.reading.getThermalScaledUrl() + '")',
      'background-position-x': '-' + ((anomaly.x - anomaly.width/4) * 6 * this.getSizeFactor(anomaly)).toString() + 'em',
      'background-position-y': '-' + ((anomaly.y - anomaly.height/4) * 8 * this.getSizeFactor(anomaly)).toString() + 'em',
      'background-size': (100 * this.getSizeFactor(anomaly)) + '%'
    }
  }

  private cmToTwip(cm: number): number {
    return cm * 20.0 * 72.0 / 2.54;
  }

  private getDefinitionCombo(name: string, value: string): TableRow {
    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: name
            })
          ]
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: value
            })
          ]
        })
      ]
    });
  }

  private getTableCombo(texts: string[]): TableRow {
    let cells: TableCell[] = [];
    for(let i: number = 0; i < texts.length; i++) {
      const text: string = texts[i];
      cells.push(
        new TableCell({
          children: [
            new Paragraph({
              text: text
            })
          ]
        })
      );
    }
    return new TableRow({
      children: cells
    });
  }

  private getIntroParagraphs(): (Paragraph | Table | HyperlinkRef | TableOfContents)[] {
    return [
      new Paragraph({
        text: 'Inngangur',
        heading: HeadingLevel.HEADING_1
      }),
      new Paragraph({
        text: this.report.introTitle,
        heading: HeadingLevel.HEADING_2
      }),
      new Paragraph({
        text: this.report.intro
      }),
      new Paragraph({
        text: 'Framkvæmd',
        heading: HeadingLevel.HEADING_3
      }),
      new Paragraph({
        text: this.report.methodology
      }),
      new Paragraph({
        text: 'Flokkun mælinga',
        heading: HeadingLevel.HEADING_3
      }),
      new Paragraph({
        text: 'Hver mæling úr hitamyndavélinni er greind og stig hennar flokkað samvæmt eftirfarandi flokkunarstaðli.'
      }),
      new Table({
        rows: [
          this.getTableCombo(['Stig', 'Lýsing', 'Aðgerð', 'Litur']),
          this.getTableCombo([
            '1',
            'Mældur hiti innan marka.',
            'Engin þörf á aðgerðum.',
            'Grænn'
          ]),
          this.getTableCombo([
            '2',
            'Mældur hiti nálægt mörkum.',
            'Æskilegt að fylgjast með búnaði og þróun hitastigs í næsta eftirliti.',
            'Gulur'
          ]),
          this.getTableCombo([
            '3',
            'Mældur hiti yfir mörkum.',
            'Æskilegt að ráðast í viðhaldsaðgerðir á búnaði við fyrsta tækifæri.',
            'Appelsínugulur'
          ]),
          this.getTableCombo([
            '4',
            'Mældur hiti hættulega hátt yfir eðlilegum mörkum búnaðar.',
            'Nauðsynlegt að ráðast í viðhaldsaðgerðir á búnaði strax.',
            'Rauður'
          ])
        ],
        borders: {
          bottom: { size: 0, color: '000000', style: BorderStyle.NIL },
          top: { size: 0, color: '000000', style: BorderStyle.NIL },
          left: { size: 0, color: '000000', style: BorderStyle.NIL },
          right: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideHorizontal: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideVertical: { size: 0, color: '000000', style: BorderStyle.NIL }
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        }
      })
    ];
  }

  private getAnomalyCombo(anomaly: InspectionReadingAnomaly, index: number): TableRow {
    const visualImage = Media.addImage(this.doc, this.getAnomalyImage(anomaly), 30, 40);
    const thermalImage = Media.addImage(this.doc, this.getAnomalyThermalImage(anomaly), 30, 40);
    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: (index + 1).toString()
            })
          ]
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                visualImage,
                thermalImage
              ]
            })
          ]
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: anomaly.minTemp.toFixed(0) + ' - ' + anomaly.maxTemp.toFixed(0) + ' °C'
            })
          ]
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'Stig ' + anomaly.severity.toString()
            })
          ]
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: anomaly.validationNote
            })
          ]
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: anomaly.validationAdvice
            })
          ]
        }),
      ]
    });
  }

  private getReadingParagraphs(reading: InspectionReading, index: number): (Paragraph | Table | HyperlinkRef | TableOfContents)[] {
    const visualImage = Media.addImage(this.doc, this.getReadingImage(reading), 150, 200);
    const thermalImage = Media.addImage(this.doc, this.getReadingThermalImage(reading), 150, 200);
    let readingParagraphs: (Paragraph | Table | HyperlinkRef | TableOfContents)[] = [
      new Paragraph({
        text: 'Athugun ' + (index + 1).toString(),
        heading: HeadingLevel.HEADING_4
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'Mæling'
                  })
                ]
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      visualImage,
                      thermalImage
                    ]
                  })
                ]
              })
            ]
          }),
          this.getDefinitionCombo('Staða mælingar', reading.status.name + ' - ' + reading.status.description),
          this.getDefinitionCombo('Dagsetning', reading.recordedAt ? reading.recordedAt.toUTCString() : ''),
          this.getDefinitionCombo('Umhverfishitastig', reading.ambientTemp.toFixed(0) + ' °C'),
          this.getDefinitionCombo('Hámarkshitastig', reading.maxTemp.toFixed(0) + ' °C'),
          this.getDefinitionCombo('Aðgerðarstig', 'Stig ' + reading.getAlertLevel().toString()),
          this.getDefinitionCombo('Athugasemd við mælingu', reading.notes),
          this.getDefinitionCombo('Staðfestingaraðili', (reading.validationUser) ? reading.validationUser.name : ''),
          this.getDefinitionCombo('Dagsetning staðfestingar', (reading.validationAt) ? reading.validationAt.toUTCString() : ''),
          this.getDefinitionCombo('Athugasemd við staðfestingu', reading.validationNote)
        ],
        borders: {
          bottom: { size: 0, color: '000000', style: BorderStyle.NIL },
          top: { size: 0, color: '000000', style: BorderStyle.NIL },
          left: { size: 0, color: '000000', style: BorderStyle.NIL },
          right: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideHorizontal: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideVertical: { size: 0, color: '000000', style: BorderStyle.NIL }
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        }
      })
    ];
    if(reading.anomalies.length > 0) {
      let rows: TableRow[] = [
        this.getTableCombo([
          'Frávik',
          'Staðsetning',
          'Hitastig',
          'Aðgerðarstig',
          'Athugasemd',
          'Aðgerð'
        ])
      ];
      for(let i: number = 0; i < reading.anomalies.length; i++) {
        rows.push(this.getAnomalyCombo(reading.anomalies[i], i));
      }
      readingParagraphs.push(
        new Paragraph({
          text: 'Frávik',
          heading: HeadingLevel.HEADING_5
        })
      );
      readingParagraphs.push(
        new Table({
          rows: rows,
          borders: {
            bottom: { size: 0, color: '000000', style: BorderStyle.NIL },
            top: { size: 0, color: '000000', style: BorderStyle.NIL },
            left: { size: 0, color: '000000', style: BorderStyle.NIL },
            right: { size: 0, color: '000000', style: BorderStyle.NIL },
            insideHorizontal: { size: 0, color: '000000', style: BorderStyle.NIL },
            insideVertical: { size: 0, color: '000000', style: BorderStyle.NIL }
          },
          width: {
            size: 100,
            type: WidthType.PERCENTAGE
          }
        })
      );
    }
    return readingParagraphs;
  }

  private getInspectionParagraphs(inspection: Inspection): (Paragraph | Table | HyperlinkRef | TableOfContents)[] {
    let inspectionParagraphs: (Paragraph | Table | HyperlinkRef | TableOfContents)[] = [
      new Paragraph({
        text: 'Skoðun á búnaði',
        heading: HeadingLevel.HEADING_3
      }),
      new Table({
        rows: [
          this.getDefinitionCombo('Heiti búnaðar', inspection.equipment.name),
          this.getDefinitionCombo('Staða', inspection.status.name + ' - ' + inspection.status.description),
          this.getDefinitionCombo('Athugasemd', inspection.notes)
        ],
        borders: {
          bottom: { size: 0, color: '000000', style: BorderStyle.NIL },
          top: { size: 0, color: '000000', style: BorderStyle.NIL },
          left: { size: 0, color: '000000', style: BorderStyle.NIL },
          right: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideHorizontal: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideVertical: { size: 0, color: '000000', style: BorderStyle.NIL }
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        }
      })
    ];

    const readings: InspectionReading[] = inspection.getOrdered();
    for(let i: number = 0; i < readings.length; i++) {
      const reading: InspectionReading = readings[i];
      inspectionParagraphs = inspectionParagraphs.concat(this.getReadingParagraphs(reading, i));
    }
    return inspectionParagraphs;
  }

  private getResultParagraphs(): (Paragraph | Table | HyperlinkRef | TableOfContents)[] {
    let resultSet: (Paragraph | Table | HyperlinkRef | TableOfContents)[] = [
      new Paragraph({
        text: 'Niðurstöður',
        heading: HeadingLevel.HEADING_1
      }),
      new Paragraph({
        text: 'Framkvæmd eftirlits',
        heading: HeadingLevel.HEADING_2
      }),
      new Table({
        rows: [
          this.getDefinitionCombo('Staðsetning', this.report.execution.inspectionPlan.facility.name),
          this.getDefinitionCombo('Dagsetning', this.report.execution.startedAt ? this.report.execution.startedAt.toUTCString() : ''),
          this.getDefinitionCombo('Tímarammi eftirlits', this.report.execution.inspectionPlan.interval.name),
          this.getDefinitionCombo('Framkvæmdaraðili', this.report.execution.user.name)
        ],
        borders: {
          bottom: { size: 0, color: '000000', style: BorderStyle.NIL },
          top: { size: 0, color: '000000', style: BorderStyle.NIL },
          left: { size: 0, color: '000000', style: BorderStyle.NIL },
          right: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideHorizontal: { size: 0, color: '000000', style: BorderStyle.NIL },
          insideVertical: { size: 0, color: '000000', style: BorderStyle.NIL }
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        }
      }),
      new Paragraph({
        text: 'Niðurstöður hitaeftirlits',
        heading: HeadingLevel.HEADING_2,
      })
    ];

    const inspections: Inspection[] = this.getInspections();
    for(let i: number = 0; i < inspections.length; i++) {
      const inspection: Inspection = inspections[i];
      resultSet = resultSet.concat(this.getInspectionParagraphs(inspection));
    }
    return resultSet;
  }

  public export(): void {

    this.feedback.say('Exporting document...');

    let sectionChildren: (Paragraph | Table | HyperlinkRef | TableOfContents)[] = [];

    const titleParagraph: Paragraph = new Paragraph({
      text: this.report.title,
      heading: HeadingLevel.TITLE
    });

    sectionChildren.push(titleParagraph);

    sectionChildren = sectionChildren.concat(this.getIntroParagraphs());

    this.feedback.say('Processing results...');

    sectionChildren = sectionChildren.concat(this.getResultParagraphs());

    this.feedback.say('Finalizing...');

    sectionChildren = sectionChildren.concat([
      new Paragraph({
        text: 'Lokaorð',
        heading: HeadingLevel.HEADING_1
      }),
      new Paragraph({
        text: this.report.conclusion
      })
    ]);

    this.doc.addSection({
      children: sectionChildren
    });

    Packer.toBlob(this.doc).then(blob => {
      saveAs(blob, 'report.docx');
    });
  }

}
