import { Component, Input, OnInit } from '@angular/core';
import { ReadingActivator } from 'src/app/reading-activator';
import { Baseline } from '../../models/baseline';
import { BaselineReading } from '../../models/baseline-reading';

@Component({
  selector: 'app-baseline',
  templateUrl: './baseline.component.html',
  styleUrls: ['./baseline.component.scss']
})
export class BaselineComponent implements OnInit {

  @Input() baseline: Baseline;

  public activeReading: BaselineReading;

  constructor() {
    this.activeReading = null;
  }

  ngOnInit(): void {
  }

  public activateReading(reading: BaselineReading) {
    this.activeReading = reading;
  }

}
