import { InspectionReading } from './inspection-reading';
import { User } from './user';
import { environment } from '../../environments/environment';
import { DetailItem, Summarizable } from '../summarizable';
import { Facility } from './facility';
import { Equipment } from './equipment';

export class InspectionAlarm implements Summarizable {
    id: number;
    inspectionReading: InspectionReading;
    active: boolean;
    deactivatedAt: Date;
    notes: string;
    severity: number;
    user: User;

    constructor(id: number, inspectionReading: InspectionReading, active: boolean, deactivatedAt: Date, notes: string, severity: number, user: User) {
        this.id = id;
        this.inspectionReading = inspectionReading;
        this.active = active;
        this.deactivatedAt = deactivatedAt;
        this.notes = notes;
        this.severity = severity;
        this.user = user;
    }

    public getLabel(): string {
        return this.inspectionReading.inspection.execution.inspectionPlan.getLabel();
    }

    public getSubLabel(): string {
        switch(this.severity) {
            case 1:
                return '%AOK%';
            case 2:
                return '%ALERT%';
            case 3:
                return '%SEVEREALERT%';
            case 4:
                return '%CRITICALALERT%'
            default:
                return '%AOK%';
        }
    }

    public getAlerts(): number[] {
        switch(this.severity) {
            case 1:
                return [1,0,0,0];
            case 2:
                return [0,1,0,0];
            case 3:
                return [0,0,1,0];
            case 4:
                return [0,0,0,1];
            default:
                return [0,0,0,0];
        }
    }

    public getImageUrl(): string {
        if(this.inspectionReading.imageLink) {
            return environment.imageUrl + this.inspectionReading.imageLink;
        }
        return environment.imageUrl + this.inspectionReading.baselineReading.imageLink;
    }

    public getType(): string {
        return 'inspection-alarm';
    }

    public getBackgroundColor(): string {
        switch(this.severity) {
            case 1:
                return 'limegreen';
            case 2:
                return 'gold';
            case 3:
                return 'coral';
            case 4:
                return 'crimson';
        }
    }

    public getPrimaryTextColor(): string {
        return 'white';
    }

    public getFacility(): Facility {
        return this.inspectionReading.inspection.equipment.facility;
    }

    public getOverviewTitle(): string {
        return 'Reading';
    }

    public getEquipment(): Equipment[] {
        return [this.inspectionReading.inspection.equipment];
    }

    public getDetails(): DetailItem[] {
        return [];
    }

    public match(term: string): boolean {
        return(
            this.inspectionReading.inspection.equipment.match(term)
         || this.inspectionReading.inspection.execution.inspectionPlan.match(term)
        );
    }

    public sortFunction(a: InspectionAlarm, b: InspectionAlarm): number {
        const aAlerts: number[] = a.getAlerts();
        const bAlerts: number[] = b.getAlerts();
        if(aAlerts[3] > 0 || bAlerts[3] > 0) {
            if(aAlerts[3] == 0) return -1;
            if(bAlerts[3] == 0) return 1;
        }
        if(aAlerts[2] > 0 || bAlerts[2] > 0) {
            if(aAlerts[2] == 0) return -1;
            if(bAlerts[2] == 0) return 1;
        }
        return 0;
    }
}
