import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService, ImageUploadResponse } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Input() value: string;
  @Output() valueChange: EventEmitter<string>;

  constructor(
    private api: ApiService
  ) {
    this.valueChange = new EventEmitter<string>();
  }

  ngOnInit(): void {
  }

  public upload(images: FileList): void {
    if(images.length > 0) {
      const image: File = images[0];
      this.api.uploadImage(image).subscribe(
        (data: ImageUploadResponse) => {
          this.value = data.file_id;
          this.valueChange.emit(data.file_id);
        }
      );
    }
  }

  public imageUrl(): string {
    if(this.value) {
      return environment.imageUrl + this.value;
    }
    return '';
  }

}
