import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { InspectionPlan } from 'src/app/models/inspection-plan';
import { StateService } from 'src/app/services/state.service';
import { Summarizable } from '../../summarizable';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnChanges {

  @Input() items: Summarizable[];

  @Input() filtered: boolean;
  @Input() limit: number;

  constructor(
    private state: StateService
  ) {
    this.filtered = true;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  public planFilter(item: Summarizable) {
    return (<InspectionPlan>item).relatedToUser(this.state.getCurrentUser());
  }

  public sortedItems(): Summarizable[] {
    if(this.items.length > 0 
      && this.items[0].getType() == 'inspection-plan'
      && this.filtered
    ) {
      this.items = this.items.filter(this.planFilter.bind(this));
    }
    if(this.items.length > 0) {
      const item: Summarizable = this.items[0];
      if(this.limit) {
        return this.items.sort(item.sortFunction).reverse().slice(0,4);
      }
      return this.items.sort(item.sortFunction).reverse();
    }
    return [];
  }

}
