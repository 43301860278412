import { Facility } from './facility';
import { EquipmentType } from './equipment-type';
import { Inspection } from './inspection';
import { Summarizable, DetailItem  } from '../summarizable';
import { environment } from 'src/environments/environment';
import { AnalysisProfile } from './analysis-profile';
import { Baseline } from './baseline';
import { InspectionPlan } from './inspection-plan';
import { User } from './user';

export class Equipment implements Summarizable {
    id: number;
    name: string;
    type: EquipmentType;
    description: string;
    facility: Facility;
    imageLink: string;
    location: string;
    createdBy: User;
    createdAt: Date;

    inspections: Inspection[];
    baselines: Baseline[];
    inspectionPlans: InspectionPlan[];

    constructor(id: number, name: string, type: EquipmentType, description: string, facility: Facility, imageLink: string, location: string, createdBy: User, createdAt: Date) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.description = description;
        this.facility = facility;
        this.imageLink = imageLink;
        this.location = location;
        this.createdBy = createdBy;
        this.createdAt = createdAt;

        this.inspections = [];
        this.baselines = [];
        this.inspectionPlans = [];
    }

    public addInspection(inspection: Inspection): void {
        this.inspections.push(inspection);
    }

    public addBaseline(baseline: Baseline): void {
        this.baselines.push(baseline);
    }

    public addInspectionPlan(plan: InspectionPlan) {
        this.inspectionPlans.push(plan);
    }

    public getLabel(): string {
        return this.name;
    }

    public getSubLabel(): string {
        return this.description;
    }

    public getActiveBaseline(): Baseline {
        for(let i: number = this.baselines.length - 1; i >= 0; i--) {
            const baseline: Baseline = this.baselines[i];
            if(baseline.active) {
                return baseline;
            }
        }
        return null;
    }

    private getActiveProfile(): AnalysisProfile {
        const baseline: Baseline = this.getActiveBaseline();
        if(baseline) {
            return baseline.profile;
        }
        return null;
    }

    private getAnalysisProfileBody(): string {
        const profile: AnalysisProfile = this.getActiveProfile();
        if(profile) {
            return profile.name;
        }
        return 'No profile';
    }

    private getInspectionPlansBody(): string[] {
        let body: string[] = [];
        for(let i: number = 0; i < this.inspectionPlans.length; i++) {
            const plan: InspectionPlan = this.inspectionPlans[i];
            body.push(plan.getLabel());
        }
        return body;
    }

    private formatDate(date: Date): string {
        return [date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear()].join('.');
    }

    private getLastInspection(plan?: InspectionPlan): Inspection {
        let newest: Inspection = null;
        for(let i: number = 0; i < this.inspections.length; i++) {
            const inspection: Inspection = this.inspections[i];
            if(
                inspection.execution
                && inspection.execution.startedAt
                && (!plan || inspection.execution.inspectionPlan == plan)
                && (!newest || inspection.execution.startedAt > newest.execution.startedAt)
            ) {
                newest = inspection;
            }
        }
        return newest;
    }

    private getLastInspectionBody(): string {
        const inspection: Inspection = this.getLastInspection();
        if(inspection) {
            return this.formatDate(inspection.execution.startedAt);
        }
        return 'No inspections';
    }

    public getDetails(): DetailItem[] {
        return [
            {
                title: 'Name',
                body: [ this.name ],
                warn: false
            },
            {
                title: 'Description',
                body: [ this.description ],
                warn: false
            },
            {
                title: 'Location',
                body: [ this.location ],
                warn: false
            },
            {
                title: 'Analysis profile',
                body: [ this.getAnalysisProfileBody() ],
                warn: false
            },
            {
                title: 'Inspection plans',
                body: this.getInspectionPlansBody(),
                warn: false
            },
            {
                title: 'Last inspected',
                body: [ this.getLastInspectionBody() ],
                warn: false
            }
        ];
    }

    public getAlerts(plan?: InspectionPlan): number[] {
        const inspection: Inspection = this.getLastInspection(plan);
        if(inspection) {
            return inspection.getAlerts();
        }
        return [0, 0, 0, 0];
    }

    public getAlertLevel(): number {
        let alerts: number[] = this.getAlerts();
        for(let i: number = 3; i > 0; i--) {
            if(alerts[i] > 0) {
                return i;
            }
        }
        return 0;
    }

    public getImageUrl(): string {
        if(this.imageLink) {
            return environment.imageUrl + this.imageLink;
        }
        if(this.facility) {
            return this.facility.getImageUrl();
        }
        return '';
    }

    public getType(): string {
        return 'equipment';
    }

    public getPrimaryTextColor(): string {
        return 'black';
    }

    public getBackgroundColor(): string {
        return 'white';
    }

    public getFacility(): Facility {
        return this.facility;
    }

    public getOverviewTitle(): string {
        return 'Inspections';
    }

    public getEquipment(): Equipment[] {
        return [this];
    }

    public match(term: string): boolean {
        return (
            this.name.includes(term)
         || this.description.includes(term)
         || this.facility.match(term)
        );
    }

    public sortFunction(a: Equipment, b: Equipment): number {
        const aAlerts: number[] = a.getAlerts();
        const bAlerts: number[] = b.getAlerts();
        if(aAlerts[3] > 0 || bAlerts[3] > 0) {
            if(aAlerts[3] == 0) return -1;
            if(bAlerts[3] == 0) return 1;
        }
        if(aAlerts[2] > 0 || bAlerts[2] > 0) {
            if(aAlerts[2] == 0) return -1;
            if(bAlerts[2] == 0) return 1;
        }
        return 0;
    }

    public canDelete(user: User): boolean {
        if(this.baselines.length == 0 && this.inspectionPlans.length == 0 && this.inspections.length == 0) {
            return true;
        }
        return false;
    }
}
