import { InspectionReading } from './inspection-reading';

export class InspectionReadingAnomaly {
    id: number;
    reading: InspectionReading;
    severity: number;
    minTemp: number;
    maxTemp: number;
    width: number;
    height: number;
    x: number;
    y: number;
    validationNote: string;
    validationAdvice: string;

    constructor(
        id: number,
        reading: InspectionReading,
        severity: number,
        minTemp: number,
        maxTemp: number,
        width: number,
        height: number,
        x: number,
        y: number,
        validationNote: string,
        validationAdvice: string
    ) {
        this.id = id;
        this.reading = reading;
        this.severity = severity;
        this.minTemp = minTemp;
        this.maxTemp = maxTemp;
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
        this.validationNote = validationNote;
        this.validationAdvice = validationAdvice;
    }

    public getSeverityColor(): string {
        switch(this.severity) {
            case 1:
                return 'limegreen';
            case 2:
                return 'gold';
            case 3:
                return 'coral';
            case 4:
                return 'crimson';
            default:
                return 'darkslategray';
        }
    }
}
