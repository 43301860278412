import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloError, ApolloQueryResult, MutationOptions, WatchQueryOptions } from '@apollo/client/core';
import { AuthService } from '@auth0/auth0-angular';
import { Apollo, gql, Mutation } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import { User } from '../models/user';
import { StateService } from './state.service';
import { CacheService } from './cache.service';
import { Organization } from '../models/organization';
import { Facility } from '../models/facility';
import { Equipment } from '../models/equipment';
import { EquipmentType } from '../models/equipment-type';
import { ReadingStatus } from '../models/reading-status';
import { InspectionStatus } from '../models/inspection-status';
import { InspectionInterval } from '../models/inspection-interval';
import { AnalysisProfile } from '../models/analysis-profile';
import { InspectionPlan } from '../models/inspection-plan';
import { Baseline } from '../models/baseline';
import { InspectionPlanExecution } from '../models/inspection-plan-execution';
import { BaselineReading } from '../models/baseline-reading';
import { Inspection } from '../models/inspection';
import { InspectionReading } from '../models/inspection-reading';
import { InspectionAlarm } from '../models/inspection-alarm';
import { InspectionReadingAnomaly } from '../models/inspection-reading-anomaly';
import { FeedbackService } from './feedback.service';
import { EquipmentSelection } from '../components/inspection-plan-form/inspection-plan-form.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ImageUploadResponse {
  file_id: string,
  name: string,
  content_type: string,
  file_size: number,
  created_at: Date,
  uploaded_at: Date,
  user_id: number
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private userData: any;
  private loading: boolean;
  private error: ApolloError;

  private equipmentTypesLoaded: boolean;
  private readingStatusesLoaded: boolean;
  private inspectionStatusesLoaded: boolean;
  private inspectionIntervalsLoaded: boolean;
  private organizationsLoaded: boolean;
  private analysisProfilesLoaded: boolean;
  private usersLoaded: boolean;
  private membershipsLoaded: boolean;
  private facilitiesLoaded: boolean;
  private inspectionPlansLoaded: boolean;
  private equipmentLoaded: boolean;
  private inspectionPlanItemsLoaded: boolean;
  private baselinesLoaded: boolean;
  private baselineReadingsLoaded: boolean;
  private inspectionPlanExecutionsLoaded: boolean;
  private inspectionsLoaded: boolean;
  private inspectionReadingsLoaded: boolean;
  private inspectionReadingAnomaliesLoaded: boolean;
  private inspectionAlarmsLoaded: boolean;


  constructor(
    private apollo: Apollo,
    private auth: AuthService,
    private state: StateService,
    private cache: CacheService,
    private feedback: FeedbackService,
    private http: HttpClient
  ) {
    this.userData = null;

    this.equipmentTypesLoaded = false;
    this.readingStatusesLoaded = false;
    this.inspectionStatusesLoaded = false;
    this.inspectionIntervalsLoaded = false;
    this.organizationsLoaded = false;
    this.analysisProfilesLoaded = false;
    this.usersLoaded = false;
    this.membershipsLoaded = false;
    this.facilitiesLoaded = false;
    this.inspectionPlansLoaded = false;
    this.equipmentLoaded = false;
    this.inspectionPlanItemsLoaded = false;
    this.baselinesLoaded = false;
    this.baselineReadingsLoaded = false;
    this.inspectionPlanExecutionsLoaded = false;
    this.inspectionsLoaded = false;
    this.inspectionReadingsLoaded = false;
    this.inspectionReadingAnomaliesLoaded = false;
    this.inspectionAlarmsLoaded = false;

    this.auth.user$.subscribe({next: user => this.init(user)});
  }

  private init(user: any): void {
    this.userData = user;
    this.feedback.say('Retrieving data from server...');
    this.fetchEquipmentTypes();
    this.fetchReadingStatuses();
    this.fetchInspectionStatuses();
    this.fetchInspectionIntervals();
    this.fetchOrganizations();
  }

  private fetchEquipmentTypes(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query EquipmentType {
          ti_equipment_type {
            id
            type
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processEquipmentTypes(result);
      }
    );
  }

  private fetchReadingStatuses(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query ReadingStatus {
          ti_reading_status {
            description
            id
            name
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processReadingStatuses(result);
      }
    );
  }

  private fetchInspectionStatuses(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionStatus {
          ti_inspection_status {
            description
            id
            name
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionStatuses(result);
      }
    );
  }

  private fetchInspectionIntervals(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionInterval {
          ti_inspection_interval {
            id
            interval
            name
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionIntervals(result);
      }
    );
  }

  private fetchOrganizations(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Organizations {
          ti_organizations {
            active
            description
            id
            image_link
            name
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processOrganizations(result);
      }
    );
  }

  private processEquipmentTypes(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_equipment_type'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      const type: EquipmentType = new EquipmentType(item.id, item.type);
      this.cache.updateEquipmentType(type);
    }
    this.equipmentTypesLoaded = true;
    this.checkEquipment();
  }

  private processReadingStatuses(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_reading_status'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      const status: ReadingStatus = new ReadingStatus(
        item.id,
        item.name,
        item.description
      );
      this.cache.updateReadingStatus(status);
    }
    this.readingStatusesLoaded = true;
    this.checkInspectionReadings();
  }

  private processInspectionStatuses(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_inspection_status'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      const status: InspectionStatus = new InspectionStatus(
        item.id,
        item.name,
        item.description
      );
      this.cache.updateInspectionStatus(status);
    }
    this.inspectionStatusesLoaded = true;
    this.checkInspectionPlanExecutions();
    this.checkInspections();
  }

  private processInspectionIntervals(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_inspection_interval'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      const interval: InspectionInterval = new InspectionInterval(
        item.id,
        item.name,
        item.interval
      );
      this.cache.updateInspectionInterval(interval);
    }
    this.inspectionIntervalsLoaded = true;
    this.checkInspectionPlans();
  }

  private processOrganizations(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_organizations'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      const organization: Organization = new Organization(
        item.id,
        item.name,
        item.description,
        item.image_link,
        item.active
      )
      this.cache.updateOrganization(organization);
    }
    this.organizationsLoaded = true;
    this.fetchAnalysisProfiles();
    this.fetchUsers();
  }

  private fetchAnalysisProfiles(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query AnalysisProfile {
          ti_analysis_profile {
            description
            id
            is_default
            name
            organization_id
            range_max
            range_min
            stage_2_threshold
            stage_3_threshold
            stage_4_threshold
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processAnalysisProfiles(result);
      }
    );
  }

  private fetchUsers(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Users {
          ti_users {
            active
            auth_key
            email
            id
            image_link
            last_active_organization
            name
            phone_1
            phone_2
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processUsers(result);
      }
    );
  }

  private processAnalysisProfiles(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_analysis_profile'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let organization: Organization = null;
      if(
        item.organization_id
        && this.cache.hasOrganization(item.organization_id)
      ) {
        organization = this.cache.getOrganization(item.organization_id);
      }
      const profile: AnalysisProfile = new AnalysisProfile(
        item.id,
        item.name,
        organization,
        item.description,
        item.is_default,
        item.range_min,
        item.range_max,
        [item.stage_2_threshold, item.stage_3_threshold, item.stage_4_threshold]
      );
      this.cache.updateAnalysisProfile(profile);
      if(organization) {
        organization.addProfile(profile);
      }
    }
    this.analysisProfilesLoaded = true;
    this.checkBaselines();
    this.checkBaselineReadings();
  }

  private processUsers(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_users'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let organization: Organization = null;
      if(
        item.last_active_organization
        && this.cache.hasOrganization(item.last_active_organization)
      ) {
        organization = this.cache.getOrganization(item.last_active_organization);
      } else {
        organization = this.cache.getFirstOrganization();
      }
      const user: User = new User(
        item.id,
        item.name,
        item.email,
        item.auth_key,
        organization,
        item.image_link,
        item.active,
        item.phone_1,
        item.phone_2
      );
      this.cache.updateUser(user);
    }
    this.usersLoaded = true;
    this.fetchMemberships();
    this.fetchFacilities();
    this.checkBaselines();
    this.checkInspectionPlanExecutions();
    this.checkInspectionAlarms();

    this.feedback.say('Setting current user...');

    const user: User = this.cache.getUserByKey(this.userData['https://hasura.io/jwt/claims']['x-hasura-user-id']);

    if(!user.lastActiveOrganization) {
      const organization: Organization = this.cache.getFirstOrganization();
      user.lastActiveOrganization = organization;
    }

    this.state.setCurrentUser(user);
  }

  private fetchFacilities(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Facilities {
          ti_facility {
            address
            country
            description
            id
            image_link
            name
            organization_id
            created_by
            created_at
          }
        }
      `
    };
    
    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processFacilities(result);
      }
    );
  }

  private processFacilities(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_facility'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let organization: Organization = null;
      if(
        item.organization_id
        && this.cache.hasOrganization(item.organization_id)
      ) {
        organization = this.cache.getOrganization(item.organization_id);
      }
      let createdBy: User = null;
      if(
        item.created_by
        && this.cache.hasUser(item.created_by)
      ) {
        createdBy = this.cache.getUser(item.created_by);
      }
      let createdAt: Date = null;
      if(item.created_at) {
        createdAt = new Date(item.created_at);
      }
      const facility: Facility = new Facility(
        item.id,
        item.name,
        item.description,
        item.image_link,
        organization,
        item.address,
        item.country,
        createdBy,
        createdAt
      );
      this.cache.updateFacility(facility);
      if(organization) {
        organization.addFacility(facility);
      }
    }
    this.facilitiesLoaded = true;
    this.checkInspectionPlans();
    this.checkEquipment();
  }

  private checkInspectionPlans(): void {
    if(
      this.facilitiesLoaded
      && this.inspectionIntervalsLoaded
    ) {
      this.fetchInspectionPlans();
    }
  }

  private checkEquipment(): void {
    if(
      this.facilitiesLoaded
      && this.equipmentTypesLoaded
    ) {
      this.fetchEquipment();
    }
  }

  private fetchMemberships(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Memberships {
          ti_org_users {
            id
            org_id
            user_id
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processMemberships(result);
      }
    );
  }

  private fetchInspectionPlans(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionPlan {
          ti_inspection_plan {
            description
            facility_id
            id
            interval_plan
            name
            next_inspection
            created_by
            created_at
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionPlans(result);
      }
    );
  }

  private fetchEquipment(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Equipment {
          ti_equipment {
            description
            facility_id
            id
            image_link
            location
            name
            type_id
            created_by
            created_at
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processEquipment(result);
      }
    );
  }

  private processMemberships(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_org_users'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      if(
        this.cache.hasOrganization(item.org_id)
        && this.cache.hasUser(item.user_id)
      ) {
        const organization: Organization = this.cache.getOrganization(item.org_id);
        const user: User = this.cache.getUser(item.user_id);
        organization.addUser(user);
        user.addOrganization(organization);
      }
    }
    this.membershipsLoaded = true;
  }

  private processInspectionPlans(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_inspection_plan'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let facility: Facility = null;
      if(
        item.facility_id
        && this.cache.hasFacility(item.facility_id)
      ) {
        facility = this.cache.getFacility(item.facility_id);
      }
      let interval: InspectionInterval = null;
      if(
        item.interval_plan
        && this.cache.hasInspectionInterval(item.interval_plan)
      ) {
        interval = this.cache.getInspectionInterval(item.interval_plan);
      }
      let date: Date = null;
      if(item.next_inspection) {
        date = new Date(item.next_inspection);
      }
      let createdBy: User = null;
      if(
        item.created_by
        && this.cache.hasUser(item.created_by)
      ) {
        createdBy = this.cache.getUser(item.created_by);
      }
      let createdAt: Date = null;
      if(item.created_at) {
        createdAt = new Date(item.created_at);
      }
      const plan: InspectionPlan = new InspectionPlan(
        item.id,
        item.name,
        facility,
        item.description,
        interval,
        date,
        createdBy,
        createdAt
      );
      this.cache.updateInspectionPlan(plan);
      if(facility) {
        facility.addInspectionPlan(plan);
      }
    }
    this.inspectionPlansLoaded = true;
    this.checkInspectionPlanItems();
    this.checkInspectionPlanExecutions();
  }

  private processEquipment(result: ApolloQueryResult<any>): void {
    const data = result.data['ti_equipment'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let facility: Facility = null;
      if(
        item.facility_id
        && this.cache.hasFacility(item.facility_id)
      ) {
        facility = this.cache.getFacility(item.facility_id);
      }
      let type: EquipmentType = null;
      if(
        item.type_id
        && this.cache.hasEquipmentType(item.type_id)
      ) {
        type = this.cache.getEquipmentType(item.type_id);
      }
      let createdBy: User = null;
      if(
        item.created_by
        && this.cache.hasUser(item.created_by)
      ) {
        createdBy = this.cache.getUser(item.created_by);
      }
      let createdAt: Date = null;
      if(item.created_at) {
        createdAt = new Date(item.created_at);
      }
      const equipment: Equipment = new Equipment(
        item.id,
        item.name,
        type,
        item.description,
        facility,
        item.image_link,
        item.location,
        createdBy,
        createdAt
      );
      this.cache.updateEquipment(equipment);
      if(facility) {
        facility.addEquipment(equipment);
      }
    }
    this.equipmentLoaded = true;
    this.checkInspectionPlanItems();
    this.checkBaselines();
    this.checkInspections();
  }

  private checkInspectionPlanItems(): void {
    if(
      this.equipmentLoaded
      && this.inspectionPlansLoaded
    ) {
      this.fetchInspectionPlanItems();
    }
  }

  private checkBaselines(): void {
    if(
      this.equipmentLoaded
      && this.analysisProfilesLoaded
      && this.usersLoaded
    ) {
      this.fetchBaselines();
    }
  }

  private checkInspectionPlanExecutions(): void {
    if(
      this.inspectionPlansLoaded
      && this.usersLoaded
      && this.inspectionStatusesLoaded
    ) {
      this.fetchInspectionPlanExecutions();
    }
  }

  private fetchInspectionPlanItems(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionPlanItem {
          ti_inspection_plan_item {
            equipment_id
            id
            inspection_plan_id
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionPlanItems(result);
      }
    );
  }

  private fetchBaselines(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Baseline {
          ti_baseline {
            created
            active
            equipment_id
            grid_columns
            grid_rows
            id
            profile_id
            user_id
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processBaselines(result);
      }
    );
  }

  private fetchInspectionPlanExecutions(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionPlanExecution {
          ti_inspection_plan_execution {
            id
            inspection_plan_id
            notes
            started_at
            user_id
            validation_notes
            status
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionPlanExecutions(result);
      }
    );
  }

  private processInspectionPlanItems(result: ApolloQueryResult<EmptyObject>): void {
    const data: any = result.data['ti_inspection_plan_item'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let equipment: Equipment = null;
      if(
        item.equipment_id
        && this.cache.hasEquipment(item.equipment_id)
      ) {
        equipment = this.cache.getEquipment(item.equipment_id);
      }
      let plan: InspectionPlan = null;
      if(
        item.inspection_plan_id
        && this.cache.hasInspectionPlan(item.inspection_plan_id)
      ) {
        plan = this.cache.getInspectionPlan(item.inspection_plan_id);
      }

      if(equipment && plan) {
        equipment.addInspectionPlan(plan);
        plan.addEquipment(equipment);
      }
    }
    this.inspectionPlanItemsLoaded = true;
  }

  private processBaselines(result: ApolloQueryResult<EmptyObject>): void {
    const data: any = result.data['ti_baseline'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let equipment: Equipment = null;
      if(
        item.equipment_id
        && this.cache.hasEquipment(item.equipment_id)
      ) {
        equipment = this.cache.getEquipment(item.equipment_id);
      }
      let created: Date = null;
      if(item.created) {
        created = new Date(item.created);
      }
      let profile: AnalysisProfile = null;
      if(
        item.profile_id
        && this.cache.hasAnalysisProfile(item.profile_id)
      ) {
        profile = this.cache.getAnalysisProfile(item.profile_id);
      }
      let user: User = null;
      if(
        item.user_id
        && this.cache.hasUser(item.user_id)
      ) {
        user = this.cache.getUser(item.user_id);
      }
      const baseline: Baseline = new Baseline(
        item.id,
        equipment,
        item.active,
        created,
        item.grid_columns,
        item.grid_rows,
        profile,
        user
      );
      this.cache.updateBaseline(baseline);
      if(equipment) {
        equipment.addBaseline(baseline);
      }
    }
    this.baselinesLoaded = true;
    this.checkBaselineReadings();
  }

  private processInspectionPlanExecutions(result: ApolloQueryResult<EmptyObject>): void {
    const data: any = result.data['ti_inspection_plan_execution'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let plan: InspectionPlan = null;
      if(
        item.inspection_plan_id
        && this.cache.hasInspectionPlan(item.inspection_plan_id)
      ) {
        plan = this.cache.getInspectionPlan(item.inspection_plan_id);
      }
      let status: InspectionStatus = null;
      if(
        item.status
        && this.cache.hasInspectionStatus(item.status)
      ) {
        status = this.cache.getInspectionStatus(item.status);
      }
      let user: User = null;
      if(
        item.user_id
        && this.cache.hasUser(item.user_id)
      ) {
        user = this.cache.getUser(item.user_id);
      }
      let startedAt: Date = null;
      if(item.started_at) {
        startedAt = new Date(item.started_at);
      }
      const execution: InspectionPlanExecution = new InspectionPlanExecution(
        item.id,
        item.name,
        plan,
        status,
        user,
        item.notes,
        startedAt,
        item.validation_notes
      );
      this.cache.updateInspectionPlanExecution(execution);
      if(plan) {
        plan.addExecution(execution);
      }
      if(user) {
        user.addInspectionPlanExecution(execution);
      }
    }
    this.inspectionPlanExecutionsLoaded = true;
    this.checkInspections();
  }

  private checkBaselineReadings(): void {
    if(
      this.baselinesLoaded
      && this.analysisProfilesLoaded
    ) {
      this.fetchBaselineReadings();
    }
  }

  private checkInspections(): void {
    if(
      this.equipmentLoaded
      && this.inspectionPlanExecutionsLoaded
      && this.inspectionStatusesLoaded
    ) {
      this.fetchInspections();
    }
  }

  private fetchBaselineReadings(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query BaselineReading {
          ti_baseline_reading {
            active
            baseline_id
            emissivity
            grid_column
            grid_row
            id
            image_link
            notes
            profile_id
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processBaselineReadings(result);
      }
    );
  }

  private fetchInspections(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query Inspection {
          ti_inspection {
            equipment_id
            id
            inspection_plan_excecution_id
            notes
            status
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspections(result);
      }
    );
  }

  private processBaselineReadings(result: ApolloQueryResult<any>): void {
    const data: any = result.data['ti_baseline_reading'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let baseline: Baseline = null;
      if(
        item.baseline_id
        && this.cache.hasBaseline(item.baseline_id)
      ) {
        baseline = this.cache.getBaseline(item.baseline_id);
      }
      let profile: AnalysisProfile = null;
      if(
        item.profile_id
        && this.cache.hasAnalysisProfile(item.profile_id)
      ) {
        profile = this.cache.getAnalysisProfile(item.profile_id);
      }
      const reading: BaselineReading = new BaselineReading(
        item.id,
        item.active,
        baseline,
        item.emissivity,
        item.grid_column,
        item.grid_row,
        item.image_link,
        item.notes,
        profile
      );
      this.cache.updateBaselineReading(reading);
      if(baseline) {
        baseline.addBaselineReading(reading);
      }
    }
    this.baselineReadingsLoaded = true;
    this.checkInspectionReadings();
  }

  private processInspections(result: ApolloQueryResult<any>): void {
    const data: any = result.data['ti_inspection'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let equipment: Equipment = null;
      if(
        item.equipment_id
        && this.cache.hasEquipment(item.equipment_id)
      ) {
        equipment = this.cache.getEquipment(item.equipment_id);
      }
      let execution: InspectionPlanExecution = null;
      if(
        item.inspection_plan_excecution_id
        && this.cache.hasInspectionPlanExecution(item.inspection_plan_excecution_id)
      ) {
        execution = this.cache.getInspectionPlanExecution(item.inspection_plan_excecution_id);
      }
      let status: InspectionStatus = null;
      if(
        item.status
        && this.cache.hasInspectionStatus(item.status)
      ) {
        status = this.cache.getInspectionStatus(item.status);
      }
      const inspection: Inspection = new Inspection(
        item.id,
        equipment,
        execution,
        item.notes,
        status
      );
      this.cache.updateInspection(inspection);
      if(equipment) {
        equipment.addInspection(inspection);
      }
      if(execution) {
        execution.addInspection(inspection);
      }
    }
    this.inspectionsLoaded = true;
    this.checkInspectionReadings();
  }

  private checkInspectionReadings(): void {
    if(
      this.baselineReadingsLoaded
      && this.inspectionsLoaded
      && this.readingStatusesLoaded
    ) {
      this.fetchInspectionReadings();
    }
  }

  private fetchInspectionReadings(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionReading {
          ti_inspection_reading {
            ambient_temp
            baseline_reading_id
            id
            image_link
            inspection_id
            max_temp
            notes
            recorded_at
            status
            validation_note
            validation_user_id
            validation_at
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionReadings(result);
      }
    );
  }

  private processInspectionReadings(result: ApolloQueryResult<any>): void {
    const data: any = result.data['ti_inspection_reading'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let baselineReading: BaselineReading = null;
      if(
        item.baseline_reading_id
        && this.cache.hasBaselineReading(item.baseline_reading_id)
      ) {
        baselineReading = this.cache.getBaselineReading(item.baseline_reading_id);
      }
      let inspection: Inspection = null;
      if(
        item.inspection_id
        && this.cache.hasInspection(item.inspection_id)
      ) {
        inspection = this.cache.getInspection(item.inspection_id);
      }
      let status: ReadingStatus = null;
      if(
        item.status
        && this.cache.hasReadingStatus(item.status)
      ) {
        status = this.cache.getReadingStatus(item.status);
      }
      let recordedAt: Date = null;
      if(item.recorded_at) {
        recordedAt = new Date(item.recorded_at);
      }
      let validationUser: User = null;
      if(
        item.validation_user_id
        && this.cache.hasUser(item.validation_user_id)
      ) {
        validationUser = this.cache.getUser(item.validation_user_id);
      }
      let validationAt: Date = null;
      if(item.validation_at) {
        validationAt = new Date(item.validation_at);
      }
      const reading: InspectionReading = new InspectionReading(
        item.id,
        item.ambient_temp,
        baselineReading,
        item.image_link,
        inspection,
        item.max_temp,
        item.notes,
        recordedAt,
        status,
        validationUser,
        item.validation_note,
        validationAt
      );
      this.cache.updateInspectionReading(reading);
      if(baselineReading) {
        baselineReading.addInspectionReading(reading);
      }
      if(inspection) {
        inspection.addReading(reading);
      }
      if(validationUser) {
        validationUser.addInspectionPlanReadingValidation(reading);
      }
    }
    this.inspectionReadingsLoaded = true;
    this.checkInspectionAlarms();
    this.fetchInspectionReadingAnomalies();
  }

  private checkInspectionAlarms(): void {
    if(
      this.usersLoaded
      && this.inspectionReadingsLoaded
    ) {
      this.fetchInspectionAlarms();
    }
  }

  private fetchInspectionReadingAnomalies(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionReadingAnomaly {
          ti_inspection_reading_anamoly {
            height
            id
            min_temp
            max_temp
            severity
            reading_id
            width
            y
            x
            validation_note
            validation_advice
          }
        }
      `
      };

      this.apollo
      .watchQuery(options)
      .valueChanges.subscribe(
        result => {
          this.processInspectionReadingAnomalies(result);
        }
      );
  }

  private fetchInspectionAlarms(): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionAlarm {
          ti_inspection_alarm {
            active
            deactivated_at
            id
            notes
            inspection_reading_id
            severity
            user_id
          }
        }
      `
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionAlarms(result);
      }
    );
  }

  private processInspectionReadingAnomalies(result: ApolloQueryResult<any>): void {
    const data: any = result.data['ti_inspection_reading_anamoly'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let reading: InspectionReading = null;
      if(
        item.reading_id
        && this.cache.hasInspectionReading(item.reading_id)
      ) {
        reading = this.cache.getInspectionReading(item.reading_id);
      }
      const anomaly: InspectionReadingAnomaly = new InspectionReadingAnomaly(
        item.id,
        reading,
        item.severity,
        item.min_temp,
        item.max_temp,
        item.width,
        item.height,
        item.x,
        item.y,
        item.validation_note,
        item.validation_advice
      );
      this.cache.updateInspectionReadingAnomaly(anomaly);
      if(reading) {
        reading.addAnomaly(anomaly);
      }
    }
    this.inspectionReadingAnomaliesLoaded = true;
  }

  private processInspectionAlarms(result: ApolloQueryResult<any>): void {
    const data: any = result.data['ti_inspection_alarm'];
    for(let i: number = 0; i < data.length; i++) {
      const item: any = data[i];
      let reading: InspectionReading = null;
      if(
        item.inspection_reading_id
        && this.cache.hasInspectionReading(item.inspection_reading_id)
      ) {
        reading = this.cache.getInspectionReading(item.inspection_reading_id);
      }
      let user: User = null;
      if(
        item.user_id
        && this.cache.hasUser(item.user_id)
      ) {
        user = this.cache.getUser(item.user_id);
      }
      let deactivatedAt: Date = null;
      if(item.deactivated_at) {
        deactivatedAt = new Date(item.deactivated_at);
      }
      const alarm: InspectionAlarm = new InspectionAlarm(
        item.id,
        reading,
        item.active,
        deactivatedAt,
        item.notes,
        item.severity,
        user
      );
      this.cache.updateInspectionAlarm(alarm);
      if(reading) {
        reading.addAlarm(alarm);
      }
    }
    this.inspectionAlarmsLoaded = true;
  }

  private processInspectionReadingAnomaly(reading: InspectionReading, data: any): InspectionReadingAnomaly {
    let anomaly: InspectionReadingAnomaly;
    if(this.cache.hasInspectionReadingAnomaly(data.id)) {
      anomaly = this.cache.getInspectionReadingAnomaly(data.id);
    } else {
      anomaly = new InspectionReadingAnomaly(data.id, reading, null, null, null, null, null, null, null, null, null);
      reading.addAnomaly(anomaly);
      this.cache.updateInspectionReadingAnomaly(anomaly);
    }

    anomaly.height = data.height;
    anomaly.maxTemp = data.max_temp;
    anomaly.minTemp = data.min_temp;
    anomaly.severity = data.severity;
    anomaly.validationAdvice = data.validation_advice;
    anomaly.validationNote = data.validation_note;
    anomaly.width = data.width;
    anomaly.x = data.x;
    anomaly.y = data.y;

    return anomaly;
  }

  private processInspectionReading(inspection: Inspection, data: any): InspectionReading {
    let reading: InspectionReading;
    if(this.cache.hasInspectionReading(data.id)) {
      reading = this.cache.getInspectionReading(data.id);
    } else {
      reading = new InspectionReading(data.id, null, null, null, inspection, null, null, null, null, null, null, null);
      inspection.addReading(reading);
      this.cache.updateInspectionReading(reading);
    }

    reading.ambientTemp = data.ambient_temp;
    reading.baselineReading = this.cache.getBaselineReading(data.baseline_reading_id);
    reading.imageLink = data.image_link;
    reading.maxTemp = data.max_temp;
    reading.notes = data.notes;
    reading.recordedAt = data.recorded_at && new Date(data.recorded_at) || null;
    reading.status = this.cache.getReadingStatus(data.status);
    reading.validationAt = data.validation_at && new Date(data.validation_at) || null;
    reading.validationNote = data.validation_note;
    reading.validationUser = this.cache.getUser(data.validation_user_id);

    for(let i: number = 0; i < data.inspection_reading_anamolies.length; i++) {
      const anomalyData: any = data.inspection_reading_anamolies[i];
      const anomaly: InspectionReadingAnomaly = this.processInspectionReadingAnomaly(reading, anomalyData);
    }

    return reading;
  }

  private processInspection(execution: InspectionPlanExecution, data: any): Inspection {
    let inspection: Inspection;
    if(this.cache.hasInspection(data.id)) {
      inspection = this.cache.getInspection(data.id);
    } else {
      inspection = new Inspection(data.id, null, execution, null, null);
      execution.addInspection(inspection);
      this.cache.updateInspection(inspection);
    }

    inspection.equipment = this.cache.getEquipment(data.equipment_id);
    inspection.notes = data.notes;
    inspection.status = this.cache.getInspectionStatus(data.status);

    for(let i: number = 0; i < data.inspection_readings.length; i++) {
      const readingData: any = data.inspection_readings[i];
      const reading: InspectionReading = this.processInspectionReading(inspection, readingData);
    }

    return inspection;
  }

  private processInspectionPlanExecution(plan: InspectionPlan, data: any): InspectionPlanExecution {
    let execution: InspectionPlanExecution;
    if(this.cache.hasInspectionPlanExecution(data.id)) {
      execution = this.cache.getInspectionPlanExecution(data.id);
    } else {
      execution = new InspectionPlanExecution(data.id, null, plan, null, null, null, null, null);
      plan.addExecution(execution);
      this.cache.updateInspectionPlanExecution(execution);
    }

    execution.name = data.name;
    execution.inspectionPlan = plan;
    execution.status = this.cache.getInspectionStatus(data.status);
    execution.user = this.cache.getUser(data.user_id);
    execution.notes = data.notes;
    execution.startedAt = data.started_at && new Date(data.started_at) || null;
    execution.validationNotes = data.validation_notes;

    for(let i: number = 0; i < data.inspections.length; i++) {
      const inspectionData: any = data.inspections[i];
      const inspection: Inspection = this.processInspection(execution, inspectionData);
    }

    return execution;
  }

  private processInspectionPlan(data: any): void {
    const plan: InspectionPlan = this.cache.getInspectionPlan(data.id);
    plan.facility = this.cache.getFacility(data.facility_id);
    plan.description = data.description;
    plan.interval = this.cache.getInspectionInterval(data.interval_plan);
    plan.name = data.name;
    plan.nextInspection = data.next_inspection && new Date(data.next_inspection) || null;
    for(let i: number = 0; i < data.inspection_plan_executions.length; i++) {
      const executionData: any = data.inspection_plan_executions[i];
      const execution: InspectionPlanExecution = this.processInspectionPlanExecution(plan, executionData);
    }
  }

  public refreshInspectionPlan(plan: InspectionPlan): void {
    const options: WatchQueryOptions<EmptyObject> = {
      query: gql`
        query InspectionPlan($id: Int!) {
          ti_inspection_plan(where: {id: {_eq: $id}}) {
            id
            facility_id
            description
            interval_plan
            name
            next_inspection
            inspection_plan_executions {
              id
              notes
              started_at
              status
              user_id
              validation_notes
              inspections {
                equipment_id
                id
                inspection_plan_excecution_id
                notes
                status
                inspection_readings {
                  ambient_temp
                  baseline_reading_id
                  id
                  image_link
                  inspection_id
                  max_temp
                  notes
                  recorded_at
                  status
                  validation_at
                  validation_note
                  validation_user_id
                  inspection_reading_anamolies {
                    height
                    id
                    max_temp
                    min_temp
                    severity
                    reading_id
                    validation_advice
                    validation_note
                    width
                    x
                    y
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: plan.id
      },
      fetchPolicy: 'network-only'
    };

    this.apollo
    .watchQuery(options)
    .valueChanges.subscribe(
      result => {
        this.processInspectionPlan(result.data['ti_inspection_plan'][0]);
      }
    );
  }

  public logout(): void {
    this.auth.logout();
  }

  public loginWithRedirect(): void {
    this.auth.loginWithRedirect();
  }

  public deleteFacility(facility: Facility): void {
    facility.organization.removeFacility(facility);
    this.cache.removeFacility(facility);
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation DeleteFacility(
          $id: Int!
        ) {
          delete_ti_facility_by_pk (
            id: $id
          ) {
            id
          }
        }
      `,
      variables: {
        id: facility.id
      }
    };
    this.apollo
    .mutate(options)
    .subscribe();
  }

  public deleteInspectionPlan(plan: InspectionPlan): void {
    plan.facility.removeInspectionPlan(plan);
    this.cache.removeInspectionPlan(plan);
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation DeleteInspectionPlan(
          $id: Int!
        ) {
          delete_ti_inspection_plan_by_pk (
            id: $id
          ) {
            id
          }
        }
      `,
      variables: {
        id: plan.id
      }
    };
    this.apollo
    .mutate(options)
    .subscribe();
  }

  public deleteEquipment(equipment: Equipment): void {
    equipment.facility.removeEquipment(equipment);
    this.cache.removeEquipment(equipment);
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation DeleteEquipment(
          $id: Int!
        ) {
          delete_ti_equipment_by_pk (
            id: $id
          ) {
            id
          }
        }
      `,
      variables: {
        id: equipment.id
      }
    };
    this.apollo
    .mutate(options)
    .subscribe();
  }

  public updateFacility(facility: Facility): void {
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation UpdateFacility(
          $id: Int!
          $name: String!
          $description: String!
          $imageLink: String!
          $address: String!
          $country: String!
        ) {
          update_ti_facility (
            where: {id: {_eq: $id}},
            _set: {
              name: $name,
              description: $description,
              image_link: $imageLink,
              address: $address,
              country: $country
            }
          ) {
            affected_rows
          }
        }
      `,
      variables: {
        id: facility.id,
        name: facility.name,
        description: facility.description,
        imageLink: facility.imageLink,
        address: facility.address,
        country: facility.country
      }
    };
    this.apollo
    .mutate(options)
    .subscribe();
  }

  public updateEquipment(equipment: Equipment): void {
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation UpdateEquipment(
          $id: Int!
          $name: String!
          $typeId: Int!
          $description: String!
          $facilityId: Int!
          $imageLink: String!
          $location: String!
        ) {
          update_ti_equipment (
            where: {id: {_eq: $id}},
            _set: {
              name: $name,
              type_id: $typeId,
              description: $description,
              facility_id: $facilityId,
              image_link: $imageLink,
              location: $location
            }
          ) {
            affected_rows
          }
        }
      `,
      variables: {
        id: equipment.id,
        name: equipment.name,
        typeId: equipment.type && equipment.type.id || null,
        description: equipment.description,
        facilityId: equipment.facility && equipment.facility.id || null,
        imageLink: equipment.imageLink,
        location: equipment.location
      }
    };
    this.apollo
    .mutate(options)
    .subscribe();
  }

  public updateInspectionPlan(plan: InspectionPlan): void {
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation UpdateInspectionPlan(
          $id: Int!
          $name: String!
          $facilityId: Int!
          $description: String!
          $intervalPlan: Int!
          $nextInspection: timestamp!
        ) {
          update_ti_inspection_plan (
            where: {id: {_eq: $id}},
            _set: {
              name: $name,
              facility_id: $facilityId,
              description: $description,
              interval_plan: $intervalPlan,
              next_inspection: $nextInspection
            }
          ) {
            affected_rows
          }
        }
      `,
      variables: {
        id: plan.id,
        name: plan.name,
        facilityId: plan.facility && plan.facility.id || null,
        description: plan.description,
        intervalPlan: plan.interval && plan.interval.id || null,
        nextInspection: plan.nextInspection
      }
    };
    this.apollo
    .mutate(options)
    .subscribe();
  }

  public addInspectionPlan(plan: InspectionPlan, selection: EquipmentSelection[]): void {
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation AddInspectionPlan(
          $name: String!
          $facilityId: Int!
          $description: String!
          $intervalPlan: Int!
          $nextInspection: timestamp!
          $createdBy: Int!
          $createdAt: timestamp!
        ) {
          insert_ti_inspection_plan (
            objects: [{
              name: $name
              facility_id: $facilityId
              description: $description
              interval_plan: $intervalPlan
              next_inspection: $nextInspection
              created_by: $createdBy
              created_at: $createdAt
            }]
          ) {
            returning {
              id
            }
          }
        }
      `,
      variables: {
        name: plan.name,
        facilityId: plan.facility && plan.facility.id || null,
        description: plan.description,
        intervalPlan: plan.interval && plan.interval.id || null,
        nextInspection: plan.nextInspection,
        createdBy: plan.createdBy && plan.createdBy.id || null,
        createdAt: plan.createdAt
      }
    };
    this.apollo
    .mutate(options)
    .subscribe(({ data }) => {
      const item: any = data.insert_ti_inspection_plan.returning[0]
      plan.id = item.id;
      plan.facility.addInspectionPlan(plan);
      this.cache.updateInspectionPlan(plan);
      for(let i: number = 0; i < selection.length; i++) {
        const selItem: EquipmentSelection = selection[i];
        if(selItem.selected) {
          const itemOptions: MutationOptions<EmptyObject> = {
            mutation: gql`
              mutation AddInspectionPlanItem(
                $equipmentId: Int!
                $inspectionPlanId: Int!
              ) {
                insert_ti_inspection_plan_item_one (
                  object: {
                    equipment_id: $equipmentId
                    inspection_plan_id: $inspectionPlanId
                  }
                ) {
                  id
                }
              }
            `,
            variables: {
              equipmentId: selItem.equipment.id,
              inspectionPlanId: plan.id
            }
          };
          this.apollo
          .mutate(itemOptions)
          .subscribe();

          plan.addEquipment(selItem.equipment);
        }
      }
    });
  }

  public addEquipment(equipment: Equipment): void {
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation AddEquipment(
          $name: String!
          $typeId: Int!
          $description: String!
          $facilityId: Int!
          $imageLink: String!
          $location: String!
          $createdBy: Int!
          $createdAt: timestamp!
        ) {
          insert_ti_equipment_one (
            object: {
              name: $name
              type_id: $typeId
              description: $description
              facility_id: $facilityId
              image_link: $imageLink
              location: $location
              created_by: $createdBy
              created_at: $createdAt
            }
          ) {
            id
          }
        }
      `,
      variables: {
        name: equipment.name,
        typeId: equipment.type && equipment.type.id || null,
        description: equipment.description,
        facilityId: equipment.facility && equipment.facility.id || null,
        imageLink: equipment.imageLink,
        location: equipment.location,
        createdBy: equipment.createdBy && equipment.createdBy.id || null,
        createdAt: equipment.createdAt
      }
    };

    this.apollo
    .mutate(options)
    .subscribe(function(result: any) {
      equipment.id = result.data.id;
      equipment.facility.addEquipment(equipment);
      this.cache.updateEquipment(equipment);
    });
  }

  public addFacility(facility: Facility): void {
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation AddFacility(
          $name: String!
          $organizationId: Int!
          $imageLink: String!
          $description: String!
          $createdBy: Int!
          $createdAt: timestamp!
          $country: String!
          $address: String!
        ) {
          insert_ti_facility_one (
            object: {
              name: $name
              organization_id: $organizationId
              image_link: $imageLink
              description: $description
              created_by: $createdBy
              created_at: $createdAt
              country: $country
              address: $address
            }
          ) {
            id
          }
        }
      `,
      variables: {
        name: facility.name,
        organizationId: facility.organization && facility.organization.id || null,
        imageLink: facility.imageLink,
        description: facility.description,
        createdBy: facility.createdBy && facility.createdBy.id || null,
        createdAt: facility.createdAt,
        country: facility.country,
        address: facility.address
      }
    };

    this.apollo
    .mutate(options)
    .subscribe(function(result: any) {
      facility.id = result.data.id;
      facility.organization.addFacility(facility);
      this.cache.updateFacility(facility);
    });
  }

  public validateInspectionReading(reading: InspectionReading): void {
    for(let i: number = 0; i < reading.anomalies.length; i++) {
      const anomaly: InspectionReadingAnomaly = reading.anomalies[i];
      const options: MutationOptions<EmptyObject> = {
        mutation: gql`
          mutation ValidateAnomaly(
            $id: Int!
            $validationNote: String!
            $validationAdvice: String!
            $severity: Int!
          ) {
            update_ti_inspection_reading_anamoly (
              where: {id: {_eq: $id}}
              _set: {
                validation_note: $validationNote
                validation_advice: $validationAdvice
                severity: $severity
              }
            ) {
              affected_rows
            }
          }
        `,
        variables: {
          id: anomaly.id,
          validationNote: anomaly.validationNote || '',
          validationAdvice: anomaly.validationAdvice || '',
          severity: anomaly.severity
        }
      };

      this.apollo
      .mutate(options)
      .subscribe();
    }
    const options: MutationOptions<EmptyObject> = {
      mutation: gql`
        mutation ValidateInspectionReading(
          $id: Int!
          $validationAt: timestamp!
          $validationNote: String!
          $validationUserId: Int!
        ) {
          update_ti_inspection_reading (
            where: {id: {_eq: $id}}
            _set: {
              validation_at: $validationAt
              validation_note: $validationNote
              validation_user_id: $validationUserId
            }
          ) {
            affected_rows
          }
        }
      `,
      variables: {
        id: reading.id,
        validationAt: reading.validationAt,
        validationNote: reading.validationNote || '',
        validationUserId: reading.validationUser.id,
      }
    };

    this.apollo
    .mutate(options)
    .subscribe();
  }

  public uploadImage(image: File): Observable<ImageUploadResponse> {
    const formData: FormData = new FormData();
    formData.append('file', image, image.name);
    return this.http.post<ImageUploadResponse>(environment.imageUploadUrl, formData, {
      withCredentials: true
    });
  }
}
