import { AnalysisProfile } from './analysis-profile';
import { User } from './user';
import { BaselineReading } from './baseline-reading';
import { Equipment } from './equipment';

export class Baseline {
    id: number;
    equipment: Equipment;
    active: boolean;
    created: Date;
    gridColumns: number;
    gridRows: number;
    profile: AnalysisProfile;
    user: User;

    readings: BaselineReading[];

    constructor(id: number, equipment: Equipment, active: boolean, created: Date, gridColumns: number, gridRows: number, profile: AnalysisProfile, user: User) {
        this.id = id;
        this.equipment = equipment;
        this.active = active;
        this.created = created;
        this.gridColumns = gridColumns;
        this.gridRows = gridRows;
        this.profile = profile;
        this.user = user;

        this.readings = [];
    }

    public addBaselineReading(reading: BaselineReading): void {
        this.readings.push(reading);
    }

    private getReadingAtCoord(row: number, column: number): BaselineReading {
        for(let i: number = 0; i < this.readings.length; i++) {
            const reading: BaselineReading = this.readings[i];
            if(reading.gridRow == row && reading.gridColumn == column) {
                return reading;
            }
        }
        return null;
    }

    public getOrdered(): BaselineReading[] {
        let ordered: BaselineReading[] = [];
        for(let i: number = 0; i < this.gridRows; i++) {
            for(let j: number = 0; j < this.gridColumns; j++) {
                const reading: BaselineReading = this.getReadingAtCoord(i, j);
                if(reading) {
                    ordered.push(reading);
                }
            }
        }
        return ordered;
    }

    public getShaped(): BaselineReading[][] {
        let shaped: BaselineReading[][] = [];
        for(let i: number = 0; i < this.gridRows; i++) {
            let row: BaselineReading[] = [];
            for(let j: number = 0; j < this.gridColumns; j++) {
                row.push(this.getReadingAtCoord(i, j));
            }
            shaped.push(row);
        }
        return shaped;
    }

    public getSerial(): BaselineReading[] {
        let serial: BaselineReading[] = [];
        const shaped: BaselineReading[][] = this.getShaped();
        for(let i: number = 0; i < shaped.length; i++) {
            serial = serial.concat(shaped[i]);
        }
        return serial;
    }

    public getIndex(reading: BaselineReading): number {
        let index: number = 0;
        let serial: BaselineReading[] = this.getSerial();
        for(let i: number = 0; i < serial.length; i++) {
            const item: BaselineReading = serial[i];
            if(item) {
                if(item == reading) {
                    return index;
                }
                index++;
            }
        }
        return null;
    }
}
