import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopLabelComponent } from './components/top-label/top-label.component';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { TextFieldModule } from '@angular/cdk/text-field';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxImageZoomModule } from 'ngx-image-zoom';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogoComponent } from './components/logo/logo.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertLevelComponent } from './components/alert-level/alert-level.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SummaryComponent } from './components/summary/summary.component';
import { AlertComponent } from './components/alert/alert.component';
import { SummarizableComponent } from './components/summarizable/summarizable.component';
import { FacilitySummaryComponent } from './components/facility-summary/facility-summary.component';
import { InspectionPlanSummaryComponent } from './components/inspection-plan-summary/inspection-plan-summary.component';
import { InspectionAlarmSummaryComponent } from './components/inspection-alarm-summary/inspection-alarm-summary.component';
import { DetailsComponent } from './components/details/details.component';
import { EquipmentStatusComponent } from './components/equipment-status/equipment-status.component';
import { OverviewPanelComponent } from './components/overview-panel/overview-panel.component';
import { BaselineComponent } from './components/baseline/baseline.component';
import { BaselineReadingComponent } from './components/baseline-reading/baseline-reading.component';
import { DetailPanelComponent } from './components/detail-panel/detail-panel.component';
import { InspectionPlanFormComponent } from './components/inspection-plan-form/inspection-plan-form.component';
import { FacilityFormComponent } from './components/facility-form/facility-form.component';
import { EquipmentFormComponent } from './components/equipment-form/equipment-form.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { OrganizationSelectComponent } from './components/organization-select/organization-select.component';
import { AuthImgComponent } from './components/auth-img/auth-img.component';
import { AuthImagePipe } from './auth-image.pipe';
import { environment } from 'src/environments/environment';
import { OrganizationComponent } from './components/organization/organization.component';
import { ReportFormComponent } from './components/report-form/report-form.component';
import { FormsModule } from '@angular/forms';
import { ReportComponent } from './components/report-form/report/report.component';
import { SectionComponent } from './components/report-form/section/section.component';
import { InspectionComponent } from './components/inspection/inspection.component';
import { InspectionReadingComponent } from './components/inspection-reading/inspection-reading.component';
import { BaselineStepperComponent } from './components/baseline-stepper/baseline-stepper.component';
import { InspectionPlanComponent } from './components/inspection-plan/inspection-plan.component';
import { FacilityComponent } from './components/facility/facility.component';
import { EquipmentComponent } from './components/equipment/equipment.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { DeletionComponent } from './components/deletion/deletion.component';

@NgModule({
  declarations: [
    AppComponent,
    TopLabelComponent,
    LogoComponent,
    AlertsComponent,
    AlertLevelComponent,
    MainMenuComponent,
    SummaryComponent,
    AlertComponent,
    SummarizableComponent,
    FacilitySummaryComponent,
    InspectionPlanSummaryComponent,
    InspectionAlarmSummaryComponent,
    DetailsComponent,
    EquipmentStatusComponent,
    OverviewPanelComponent,
    BaselineComponent,
    BaselineReadingComponent,
    DetailPanelComponent,
    InspectionPlanFormComponent,
    FacilityFormComponent,
    EquipmentFormComponent,
    OrganizationSelectComponent,
    AuthImgComponent,
    AuthImagePipe,
    OrganizationComponent,
    ReportFormComponent,
    ReportComponent,
    SectionComponent,
    InspectionComponent,
    InspectionReadingComponent,
    BaselineStepperComponent,
    InspectionPlanComponent,
    FacilityComponent,
    EquipmentComponent,
    ImageUploadComponent,
    DeletionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    CdkStepperModule,
    TextFieldModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxImageZoomModule,
    HttpClientModule,
    AuthModule.forRoot({
      domain: 'kalor-ecm.eu.auth0.com',
      clientId: 'ptGnsf6Z7uD9Lkoub50hDYEsycpI7l41',
      redirectUri: window.location.origin,
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.apiUrl,
            tokenOptions: {
              audience: environment.apiUrl,
              scope: 'read:ti_users'
            }
          },
          {
            uri: environment.fileserverUrl + '/*',
            tokenOptions: {
              audience: environment.fileserverUrl
            }
          }
        ]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.apiUrl,
            withCredentials: true
          })
        };
      },
      deps: [HttpLink]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
