import { Component, Input, OnInit } from '@angular/core';
import { BaselineReading } from 'src/app/models/baseline-reading';
import { InspectionReading } from 'src/app/models/inspection-reading';
import { ReadingActivator } from 'src/app/reading-activator';
import { ApiService } from 'src/app/services/api.service';
import { StateService } from 'src/app/services/state.service';
import { Inspection } from '../../models/inspection';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit, ReadingActivator {

  @Input() inspection: Inspection;

  private activeReading: InspectionReading;

  constructor() { }

  ngOnInit(): void {
    const serial: BaselineReading[] = this.inspection.equipment.getActiveBaseline().getSerial();
    for(let i: number = 0; i < serial.length; i++) {
      const reading: BaselineReading = serial[i];
      if(reading) {
        this.activeReading = this.inspection.getLastReadingForBaselineReading(reading);
        break;
      }
    }
  }

  public getActiveReading(): InspectionReading {
    if(this.activeReading) {
      return this.activeReading;
    }
    return null;
  }

  public getNoIssuesDetected(): boolean {
    const alerts: number[] = this.inspection.getAlerts();
    return(
        alerts[3] + alerts[2] + alerts[1] == 0
     && alerts[0] > 0
    );
  }

  public getNoActiveIssues(): boolean {
    const alerts: number[] = this.inspection.getAlerts();
    for(let i: number = 0; i < 4; i++) {
      if(alerts[i] > 0) {
        return false;
      }
    }
    return true;
  }

  public activateReading(reading: BaselineReading): void {
    this.activeReading = this.inspection.getLastReadingForBaselineReading(reading);
  }

}
