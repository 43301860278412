import { Component, Input, OnInit } from '@angular/core';
import { BaselineReading } from '../../models/baseline-reading';

@Component({
  selector: 'app-baseline-reading',
  templateUrl: './baseline-reading.component.html',
  styleUrls: ['./baseline-reading.component.scss']
})
export class BaselineReadingComponent implements OnInit {

  @Input() reading: BaselineReading;

  constructor() { }

  ngOnInit(): void {
  }

  public getBackground(): string {
    return 'url(\"' + this.reading.getImageUrl() + '\")';
  }

}
