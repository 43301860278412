import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input } from '@angular/core';
import { BaselineReading } from 'src/app/models/baseline-reading';
import { Inspection } from 'src/app/models/inspection';
import { InspectionAlarm } from 'src/app/models/inspection-alarm';
import { InspectionReading } from 'src/app/models/inspection-reading';

@Component({
  selector: 'app-baseline-stepper',
  templateUrl: './baseline-stepper.component.html',
  styleUrls: ['./baseline-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: BaselineStepperComponent}]
})
export class BaselineStepperComponent extends CdkStepper {

  @Input() inspection: Inspection;

  onClick(index: number): void {
    this.selectedIndex = index;
  }

  public handleClick(reading: BaselineReading): void {
    if(reading) {
      this.onClick(this.inspection.getBaseline().getIndex(reading));
    }
  }

  public getValidatedForReading(reading: BaselineReading): boolean {
    if(this.inspection.getLastReadingForBaselineReading(reading) && this.inspection.getLastReadingForBaselineReading(reading).validationAt) {
      return true;
    }
    return false;
  }

  private getAlarmLevelClass(reading: InspectionReading): string {
    switch(reading.getAlertLevel()) {
        case 1:
            return 'ok';
        case 2:
            return 'moderate';
        case 3:
            return 'severe';
        case 4:
            return 'critical';
        default:
            return 'unknown';
    }
  }

  public getReadingClass(reading: BaselineReading): string {
    if(!reading) {
      return 'empty tile';
    }
    const inspectionReading: InspectionReading = this.inspection.getLastReadingForBaselineReading(reading);
    if(!inspectionReading) {
      return 'invalid tile';
    }
    let classes: string[] = [];
    if(inspectionReading.validationAt) {
      classes.push('validated');
    } else {
      classes.push('unvalidated');
    }
    classes.push(this.getAlarmLevelClass(inspectionReading));
    if(this.inspection.getBaseline().getIndex(reading) == this.selectedIndex) {
      classes.push('selected');
    }
    classes.push('tile');
    return classes.join(' ');
  }

  public getReadingIcon(reading: BaselineReading): string {
    if(!reading) {
      return 'not_interested';
    }
    const inspectionReading: InspectionReading = this.inspection.getLastReadingForBaselineReading(reading);
    if(!inspectionReading) {
      return 'warning';
    }
    if(inspectionReading.validationAt) {
      return 'done';
    }
    return 'more_horiz';
  }

}
