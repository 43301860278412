import { Summarizable, DetailItem } from '../summarizable';
import { Organization } from './organization';
import { environment } from '../../environments/environment';
import { InspectionPlan } from './inspection-plan';
import { Equipment } from './equipment';
import { InspectionAlarm } from './inspection-alarm';
import { User } from './user';

export class Facility implements Summarizable {

    id: number;
    name: string;
    description: string;
    imageLink: string;
    organization: Organization;
    address: string;
    country: string;
    createdBy: User;
    createdAt: Date;

    inspectionPlans: InspectionPlan[];
    equipment: Equipment[];

    constructor(id: number, name: string, description: string, imageLink: string, organization: Organization, address: string, country: string, createdBy: User, createdAt: Date) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.imageLink = imageLink;
        this.organization = organization;
        this.address = address;
        this.country = country;
        this.createdBy = createdBy;
        this.createdAt = createdAt;

        this.inspectionPlans = [];
        this.equipment = [];
    }

    public addEquipment(equipment: Equipment): void {
        this.equipment.push(equipment);
    }

    public removeEquipment(equipment: Equipment): void {
        const index: number = this.equipment.indexOf(equipment);
        if(index > -1) {
            this.equipment.splice(index, 1);
        }
    }

    public removeInspectionPlan(plan: InspectionPlan): void {
        const index: number = this.inspectionPlans.indexOf(plan);
        if(index > -1) {
            this.inspectionPlans.splice(index, 1);
        }
    }

    public addInspectionPlan(inspectionPlan: InspectionPlan): void {
        this.inspectionPlans.push(inspectionPlan);
    }

    public getLabel(): string {
        return this.name;
    }

    public getSubLabel(): string {
        let out: string[] = [];
        if(this.address) {
            out.push(this.address);
        }
        if(this.country) {
            out.push(this.country);
        }
        if(out.length > 0) {
            return out.join(', ');
        }
        return '';
    }

    public getDetails(): DetailItem[] {
        return [
            {
                title: 'Name',
                body: [ this.name ],
                warn: false
            },
            {
                title: 'Description',
                body: [ this.description ],
                warn: false
            },
            {
                title: 'Location',
                body: [
                    this.address,
                    this.country
                ],
                warn: false
            }
        ];
    }

    public getAlerts(): number[] {
        let alerts: number[] = [0, 0, 0, 0];
        for(let i: number = 0; i < this.equipment.length; i++) {
            const equipment: Equipment = this.equipment[i];
            let newAlerts: number[] = equipment.getAlerts();
            for(let j: number = 0; j < 4; j++) {
                alerts[j] += newAlerts[j];
            }
        }
        return alerts;
    }

    public getImageUrl(): string {
        if(this.imageLink) {
            return environment.imageUrl + this.imageLink;
        }
        return '';
    }

    public getType(): string {
        return 'facility';
    }

    public getBackgroundColor(): string {
        return 'white';
    }

    public getPrimaryTextColor(): string {
        return 'black';
    }

    public getFacility(): Facility {
        return this;
    }

    public getOverviewTitle(): string {
        return 'Equipment';
    }

    public getEquipment(): Equipment[] {
        return this.equipment;
    }

    public match(term: string): boolean {
        return(
            this.name.includes(term)
         || this.description.includes(term)
         || this.address.includes(term)
         || this.country.includes(term)
        );
    }

    public getAlarms(): InspectionAlarm[] {
        let alarms: InspectionAlarm[] = [];
        for(let i: number = 0; i < this.inspectionPlans.length; i++) {
            const plan: InspectionPlan = this.inspectionPlans[i];
            alarms = alarms.concat(plan.getAlarms());
        }
        return alarms;
    }

    public sortFunction(a: Facility, b: Facility): number {
        const aAlerts: number[] = a.getAlerts();
        const bAlerts: number[] = b.getAlerts();
        if(aAlerts[3] > 0 || bAlerts[3] > 0) {
            if(aAlerts[3] == 0) return -1;
            if(bAlerts[3] == 0) return 1;
        }
        if(aAlerts[2] > 0 || bAlerts[2] > 0) {
            if(aAlerts[2] == 0) return -1;
            if(bAlerts[2] == 0) return 1;
        }
        return 0;
    }

    public canDelete(user: User): boolean {
        if(this.equipment.length == 0 && this.inspectionPlans.length == 0) {
            return true;
        }
        return false;
    }
}
